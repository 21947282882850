<mat-nav-list>
    <mat-list-item (click)="openBrowserModal($event)">
        <div *ngIf="!importing">
            <span mat-line i18n="import|Import JSON file">Import JSON file</span>
        </div>
        <ng-container *ngIf="importing">
            <span mat-line i18n="import|Importing in progress">Importing in progress ...</span>
            <!-- <span mat-line>{{ imageInfo }}</span> -->
        </ng-container>
    </mat-list-item>
    <mat-progress-bar *ngIf="importing" mode="buffer" value="100"></mat-progress-bar>
</mat-nav-list>
<input type="file" accept="application/json" fxHide #uploadInput (change)="startImporting()" />