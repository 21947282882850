import {
  Component,
  Input,
  DoCheck,
  ChangeDetectionStrategy,
  HostListener,
  HostBinding,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { exhaustMap, take, map, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TBlocks } from '../../classes/Elements';
import { IpUserInterfaceService } from '../../user-interface.service';
import { IpEmailObjectStoreService } from '../../ip-email-object-store.service';
import { IpUserMiddlewaresService } from '../../user-middleware-service/ip-middlewares.service';

@Component({
  selector: 'ip-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockComponent implements DoCheck, OnDestroy, OnInit {
  @Input() block: TBlocks;
  @Input() column: TBlocks[];
  @Input() index: number;

  public isActive = false;
  private readonly componentIsDestroyed$ = new Subject();

  @HostListener('click', ['$event']) onHostClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.editBlock();
  }

  @HostBinding('class.active') get isBlockActive() {
    return this.isActive;
  }

  constructor(
    private ipMiddlewaresService: IpUserMiddlewaresService,
    private userInterfaceService: IpUserInterfaceService,
    private emailObjectStore: IpEmailObjectStoreService,
    private chRef: ChangeDetectorRef
  ) { }

  removeBlock() {
    this.ipMiddlewaresService.removeBlock(this.index, this.column, this.block).pipe(
      exhaustMap(() => this.userInterfaceService.confirmDialog$()),
      filter(removeAllowed => removeAllowed),
      take(1)
    ).subscribe(() => {
      this.emailObjectStore.removeBlock(this.index, this.column);
    });
  }

  duplicateBlock() {
    this.ipMiddlewaresService.duplicateBlock(this.index, this.column, this.block).pipe(
      map(data => this.emailObjectStore.duplicateBlock(data.index, data.column, data.block)),
      take(1)
    ).subscribe(() => {
      // this.chRef.markForCheck();
    });
  }

  editBlock() {
    this.ipMiddlewaresService.editBlock(this.block).pipe(take(1)).subscribe(block => {
      this.userInterfaceService.editBlock(block);
    });
  }

  ngOnInit() {
    this.userInterfaceService.currentEditingBlock$.pipe(
      map(currentEditingBlock => currentEditingBlock === this.block),
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(isActive => {
      this.isActive = isActive;
    });
  }

  ngDoCheck() {
    if (this.isActive) {
      this.chRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.componentIsDestroyed$.next('');
    this.componentIsDestroyed$.complete();
  }
}
