import {
  Component,
  Input,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core';
import { ImageBlock } from '../../classes/Elements';
import { createBorder, createPadding, createWidthHeight } from '../../utils';
import { IpUserImageUploaderService } from '../../user-image-uploader-service/user-image-uploader.service';
import { IpEmailBuilderService } from '../../ip-email-builder.service';

@Component({
  selector: 'ip-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent {
  @Input() block = new ImageBlock();

  @HostBinding('style.textAlign')
  get align() {
    return this.block.options.align;
  }

  get src() {
    return (
      this.block.src || 'https://dummyimage.com/600x200?text=CHANGE+ME'
    );
  }

  constructor(
    private imageUploader: IpUserImageUploaderService,
    private chRef: ChangeDetectorRef,
    public ngb: IpEmailBuilderService
  ) { }

  async uploadImage() {
    const src = await this.imageUploader.browse$().toPromise();
    if (src) {
      this.block.src = src;
      this.chRef.markForCheck();
    }
  }

  getImageStyles() {
    const { border, width, height, padding } = this.block.options;

    return {
      width: createWidthHeight(width),
      height: createWidthHeight(height),
      ...createPadding(padding),
      ...createBorder(border)
    };
  }
}
