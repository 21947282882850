import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';

import { IUserTemplateCategory, IUserTemplate } from '../../interfaces';

@Component({
  selector: 'ip-template-list-dialog',
  templateUrl: './template-list-dialog.component.html',
  styleUrls: ['./template-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateListDialogComponent {
  readonly chooseCategory$ = new BehaviorSubject<string>('latest');
  readonly currentCategoryTemplates$ = this.chooseCategory$.pipe(
    map(category => this.templateList.find(list => list.category === category) || { templates: [] }),
    pluck('templates'),
    map(templates => templates.length > 0 ? templates : null)
    // map(tmpl => Array.from({ length: 1000 }).map((_, i) => tmpl[0]))
  );
  csdTemplate: IUserTemplate;

  constructor(
    private matDialogRef: MatDialogRef<TemplateListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public templateList: IUserTemplateCategory[]
  ) { }

  chooseTemplate(template: IUserTemplate) {
    this.csdTemplate = template;
  }

  chageCategory(category: string) {
    this.chooseCategory$.next(category);
  }

  getTemplatesList() {
    return this.templateList.filter(({ category }) => category !== 'latest');
  }

  startBuilding() {
    this.chooseCategory$.pipe(take(1)).subscribe(category => {
      this.matDialogRef.close({ category, template: this.csdTemplate });
    });
  }
}
