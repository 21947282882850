import { defaultsDeep } from '../utils';
import {
  IBlockState,
  ITextBlockOptions,
  IImageBLockOptions,
  IButtonBlockOptions,
  IDividerBlockOptions,
  ISpacerBlockOptions,
  ISocialBlockOptions,
  ISocialNetwork
} from '../interfaces';

export type TBlocksOptions =
  | ITextBlockOptions
  | IImageBLockOptions
  | IButtonBlockOptions
  | IDividerBlockOptions
  | ISpacerBlockOptions
  | ISocialBlockOptions;

export abstract class AIPBlock {
  readonly type: string;
  readonly icon: string;
  public options: TBlocksOptions;
  public state: IBlockState;
}

export class TextBlock implements AIPBlock {
  readonly type = 'text';
  readonly icon = 'text_format';
  public options: ITextBlockOptions = {
    color: '#000000',
    font: {
      fallback: 'Arial, Helvetica, sans-serif',
      family: 'Roboto',
      style: 'normal',
      size: 16,
      weight: 400
    },
    lineHeight: {
      value: 24,
      unit: 'px'
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25
    }
  };

  constructor(
    public innerText?: string,
    options?: ITextBlockOptions,
    public state: IBlockState = {
      disabled: false,
      message: ''
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}

export class ImageBlock implements AIPBlock {
  readonly type = 'image';
  readonly icon = 'image';
  public options: IImageBLockOptions = {
    border: {
      color: '#cccccc',
      style: 'solid',
      width: 0,
      radius: 0
    },
    width: {
      value: 100,
      unit: 'px',
      auto: true,
      units: ['px']
    },
    height: {
      value: 100,
      unit: 'px',
      auto: true,
      units: ['px']
    },
    link: {
      href: '',
      target: '_blank'
    },
    align: 'center',
    title: '',
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  };

  constructor(
    public src = 'https://via.placeholder.com/600x200?text=CHANGE+ME',
    options?: IImageBLockOptions,
    public state: IBlockState = {
      disabled: false,
      message: ''
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}

export class ButtonBlock implements AIPBlock {
  readonly type = 'button';
  readonly icon = 'radio_button_checked';
  public options: IButtonBlockOptions = {
    backgroundColor: '#414141',
    border: {
      color: '#414141',
      style: 'solid',
      width: 0,
      radius: 3
    },
    color: '#ffffff',
    font: {
      fallback: 'Arial, Helvetica, sans-serif',
      family: 'Roboto',
      size: 13,
      style: 'normal',
      weight: 400
    },

    align: 'center',
    fullWidth: false,
    // verticalAlign: 'middle',
    lineHeight: {
      value: 120,
      unit: '%'
    },
    link: {
      href: '',
      target: '_blank'
    },
    innerPadding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25
    }
  };

  constructor(
    public innerText: string = 'Click on me',
    options?: IButtonBlockOptions,
    public state: IBlockState = {
      disabled: false,
      message: ''
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}

export class DividerBlock implements AIPBlock {
  readonly type = 'divider';
  readonly icon = 'remove';
  public options: IDividerBlockOptions = {
    border: {
      color: '#000000',
      style: 'solid',
      width: 4
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25
    }
  };

  constructor(
    options?: IDividerBlockOptions,
    public state: IBlockState = {
      disabled: false,
      message: ''
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}

export class SpacerBlock implements AIPBlock {
  readonly type = 'spacer';
  readonly icon = 'vertical_align_center';

  options: ISpacerBlockOptions = {
    height: {
      value: 20,
      unit: 'px',
      units: ['px']
    }
  };

  constructor(
    options?: ISpacerBlockOptions,
    public state: IBlockState = {
      disabled: false,
      message: ''
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}

export class SocialBlock implements AIPBlock {
  readonly type = 'social';
  readonly icon = 'share';

  options: ISocialBlockOptions = {
    align: 'center',
    mode: 'horizontal',
    font: {
      fallback: 'Arial, Helvetica, sans-serif',
      family: 'Roboto',
      style: 'normal',
      size: 16,
      weight: 400
    },
    iconSize: {
      value: 30,
      unit: 'px'
    },
    lineHeight: {
      value: 16,
      unit: 'px'
    },
    color: '#333333',
    innerPadding: {
      top: 4,
      right: 4,
      bottom: 4,
      left: 4
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25
    }
  };

  // https://mjml.io/documentation/#mjml-social
  constructor(
    public networks: ISocialNetwork[] = [],
    options?: ISocialBlockOptions,
    public state: IBlockState = {
      disabled: false,
      message: ''
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}

export type TBlocks =
  TextBlock
  | ImageBlock
  | ButtonBlock
  | DividerBlock
  | SpacerBlock
  | SocialBlock;
