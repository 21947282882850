import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import {AuthGuard} from './auth/auth.guard';
import {TemplateComponent} from './lists/template/template.component';
import {CampaignComponent} from './lists/campaign/campaign.component';
import {EmailBuilderComponent} from './email-builder/email-builder.component';
import { TitleInterceptor } from './auth/title.interceptor';
import {StatsComponentComponent} from './reports/stats-component/stats-component.component';
import { EmailItemResolver } from './resolvers/email-item.resolver';

const canActivates = [AuthGuard, TitleInterceptor];

const routes: Routes = [

  { path: '', redirectTo: '/builder/campaign', pathMatch: 'full'},
  { path: 'login', component: LoginComponent, canActivate: [TitleInterceptor]},
  { path: 'builder/:itemType', component: EmailBuilderComponent, resolve: { emailItem: EmailItemResolver }, runGuardsAndResolvers: 'always', canActivate: canActivates},
  { path: 'builder/:itemType/:id', component: EmailBuilderComponent, resolve: { emailItem: EmailItemResolver }, runGuardsAndResolvers: 'always', canActivate: canActivates},
  { path: 'templates', component: TemplateComponent, canActivate: canActivates},
  { path: 'campaigns', component: CampaignComponent, canActivate: canActivates},
  { path: 'stats', component: StatsComponentComponent, data: { name: ' :: Email Stats' } }
  ];
@NgModule({
  imports: [RouterModule.forRoot(routes,  {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [AuthGuard, TitleInterceptor]
})
export class AppRoutingModule { }
