import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IBorder } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-border',
  template: `
    <div class="group" [class.three]="isEven()">
      <mat-form-field appearance="outline" *ngIf="hasOwnProperty('width')">
        <mat-label i18n="Border Settings|Change Border Width">Width</mat-label>
        <input
          matInput
          [(ngModel)]="border.width"
          type="number"
          min="0"
          step="1"
          (input)="markForCheck()"
          i18n-placeholder placeholder="Border Settings|Change Border Width"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="hasOwnProperty('radius')">
        <mat-label i18n="Border Settings|Change Border Radius">Radius</mat-label>
        <input
          matInput
          [(ngModel)]="border.radius"
          type="number"
          min="0"
          step="1"
          (input)="markForCheck()"
          i18n-placeholder placeholder="Border Settings|Change Border Radius"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        *ngIf="hasOwnProperty('style')"
        style="min-width: 90px"
      >
        <mat-label i18n="Border Settings|Change Border Style">Style</mat-label>
        <mat-select
          placeholder="Style"
          [disabled]="!border.width"
          [(value)]="border.style"
          (selectionChange)="markForCheck()"
          disableRipple
        >
          <mat-option
            *ngFor="let style of ['solid', 'dashed', 'dotted']"
            [value]="style"
          >
            {{ style }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ip-color [options]="border" [disabled]="!border.width" *ngIf="hasOwnProperty('color')"></ip-color>
  `,
  styles: [
    `
      host: {
        display: block;
        width: 100%;
      }
      mat-form-field {
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorderComponent {
  @Input() border: IBorder;

  constructor(private emailObject: IpEmailObjectStoreService) { }

  markForCheck() {
    this.emailObject.markForCheck();
  }

  isEven(): boolean {
    return Object.keys(this.border).length % 2 === 0;
  }

  hasOwnProperty(property: string): boolean {
    return this.border.hasOwnProperty(property);
  }
}
