import { Injectable, Injector } from '@angular/core';
import { IpStorageService, IUserTemplateCategory } from '../../../core-email-builder/src/public_api';
import { SendyService } from '../auth/api/sendy.service';

@Injectable({
  providedIn: 'root'
})
export class IpTemplateStorageService extends IpStorageService {

  constructor(templateStorage: Storage) {
    super(templateStorage);
  }

  getCachedTemplateList(): IUserTemplateCategory[] {
    return [];
  }
}
