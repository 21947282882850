import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { SendyService } from "src/auth/api/sendy.service";
import { User } from "src/auth/user.model";
import { Campaign } from "src/models/campaign";
import { ItemType } from "src/models/item";
import { Template } from "src/models/template";
import { IPEmail, IpEmailBuilderService } from "../../../core-email-builder/src/public_api";
import {TemplateData} from '../auth/api/template-data.model';

@Injectable({ providedIn: 'root' })
export class EmailItemResolver implements Resolve<Template | Campaign> {
  constructor(private sendyService: SendyService,  private ngb: IpEmailBuilderService) {}

  private createItem(id: string, 
    email: IPEmail, 
    html: string, 
    itemType: ItemType,
    sendDate: Date,
    user: User) : Campaign | Template {
    const item = itemType === 'template' ?
    {
      id: id,
      itemType: 'template',
      html: html,
      email: email,
      title: (email) ? email.general.name : '',
    } as Template :
    {
      id: id,
      itemType: "campaign",
      html: html,
      title: (email) ? email.general.name : '',
      fromName: user.fromName,
      fromEmail: user.fromEmail,
      replyTo: user.replyTo,
      email: email,
      isSent: sendDate !== null,
      sendDate: sendDate
    } as Campaign;

    return item;
  }

  
  async getHtml(email: IPEmail): Promise<string> 
  {
    const response = await this.ngb.createHTMLTemplateRaw(email);
    return response.html;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Template | Campaign> | Promise<Template | Campaign> | (Template | Campaign) {
    if (!route.paramMap.has('itemType') || !route.paramMap.get('itemType')) {
        //TODO Create 404 page
        alert("Something went wrong!!!");
        return;
      }

      const user  = this.sendyService.getUser();
      const itemType = route.paramMap.get('itemType') as ItemType;

      if (route.paramMap.has('id')) {
        const id = route.paramMap.get('id');
        
        if (itemType === 'template') {
          return this.sendyService.getTemplate(id)
              .then(template => {
                return this.getHtml(template).then(html => {
                  return this.createItem(id, 
                    template, 
                    html, 
                    itemType, 
                    null, 
                    user
                  );
                });
              });
        }
        else {
          return this.sendyService.getCampaign(id)
              .then(campaign => {
                return this.getHtml(campaign.ipemail).then(html => {
                  return this.createItem(id, 
                    campaign.ipemail, 
                    html, 
                    itemType, 
                    null, 
                    user
                  );
                });
              });
        }
        
      }else{
        return this.createItem('0', null, '', itemType, null, user);
      }
    };
}