import { Component, OnInit } from '@angular/core';
import {SendyService} from '../../auth/api/sendy.service';
import {BehaviorSubject} from 'rxjs';
import {StatsDashboardData} from './StatsDashboardData';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-stats-component',
  templateUrl: './stats-component.component.html',
  styleUrls: ['./stats-component.component.scss']
})
export class StatsComponentComponent implements OnInit {

  currentDate = new Date();
  columnDefs = [{ field: 'total' }, { field: 'current' }];
  public dataSource: StatsDashboardData;
  private isInitializedSource = new BehaviorSubject<boolean>(false);
  public isInitialized$ = this.isInitializedSource.asObservable();

  constructor(
    private sendyService: SendyService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const curDate = this.datePipe.transform(new Date(), 'MMyyyy');
    this.sendyService.getEmailStats(curDate)
      .then(res => {
        this.dataSource = res;

        this.isInitializedSource.next(true);
      });
  }

}
