import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {SendyService} from './auth/api/sendy.service';
import { Router, ActivatedRoute, ParamMap} from '@angular/router';
import {map} from 'rxjs/operators';
import { TitleService } from './services/title.service';
import {environment} from './environments/environment';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'email-creator';
  isLoggedIn$: Observable<boolean>;
  pageTitle$: Observable<string>;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(
    private sendyService: SendyService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.sendyService.isLoggedIn$;
    this.pageTitle$ = this.titleService.pageTitle$;
  }

  onLogout(){
    this.sidenav.close();
    this.sendyService.logout();
  }

  onNavigateSendy() {
    window.open(environment.apiURL, '_blank');
  }
}
