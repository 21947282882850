/*
 * Public API Surface of ip-email-builder
 */

export * from './lib/interfaces';
export * from './lib/tokens';
// export * from './lib/providers';
export { deferOf, defaultsDeep, cloneDeep, isEqual } from './lib/utils';
export * from './lib/classes/DefaultEmail';
export * from './lib/classes/Elements';
export * from './lib/classes/Structure';

export * from './lib/ip-email-builder.service';
export * from './lib/user-middleware-service/ip-middlewares.service';
export * from './lib/user-rest-api-service/user-rest-api.service';
export * from './lib/ip-storage/ip-storage.service';
export { FreeUsersImageUploader } from './lib/user-image-uploader-service';
export * from './lib/user-image-uploader-service/user-image-uploader.service';

export * from './lib/ip-email-builder.module';

export * from './lib/ip-email-builder.component';
export * from './lib/components/preview.component';
// export * from './lib/components/template-list-dialog/template-list-dialog.component';
