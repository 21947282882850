import { Component, Input, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { map, debounceTime, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TextBlock } from '../../classes/Elements';
import { createPadding, createFont, createLineHeight } from '../../utils';
import { IpUserInterfaceService } from '../../user-interface.service';
import { IpEmailObjectStoreService } from '../../ip-email-object-store.service';
import { IpUserRestApiService } from '../../user-rest-api-service/user-rest-api.service';

@Component({
  selector: 'ip-text-element',
  templateUrl: './text-element.component.html',
  styleUrls: ['./text-element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextElementComponent implements OnInit, OnDestroy {
  @Input() block: TextBlock;
  setAsReadOnly$ = this.internalService.currentEditingBlock$.pipe(
    map(editingBlock => editingBlock !== this.block)
  );
  private textChanged$ = new Subject()
  private componentDestroyed$ = new Subject()

  public readonly quillInited$ = this.userRestApi.getAllUserMergeFields$.pipe(
    map(placeholder => {
      // @ts-ignore
      this.staticQuillConfig.toolbar.container.splice(-1, 0, [{ placeholder }])
    }),
    map(() => true),
    take(1)
  )

  public staticQuillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [
          { header: [1, 2, 3, 4, 5, 6, false] },
          { size: ['small', false, 'large', 'huge'] }
        ],
        [{ align: [] }, 'link', 'clean'],
        // [{ color: [], background: [] }, 'direction'],
        // ['clean']
      ],
      handlers: {
        placeholder(selector: string) {
          const range = this.quill.getSelection();
          const format = this.quill.getFormat();
          const text = this.quill.getText(range.index, range.length);
          this.quill.deleteText(range.index, text.length);
          this.quill.insertText(range.index, selector, format);
          // this.quill.setSelection(range.index, selector.length);
        }
      }
    }
  }

  constructor(
    private userRestApi: IpUserRestApiService,
    private emailObject: IpEmailObjectStoreService,
    private internalService: IpUserInterfaceService,
  ) { }

  getTextStyles() {
    const { color, font, lineHeight, padding } = this.block.options;

    return {
      color,
      'word-break': 'break-word',
      ...createLineHeight(lineHeight),
      ...createFont(font),
      ...createPadding(padding)
    };
  }

  contentChanged() {
    this.textChanged$.next()
  }

  ngOnInit() {
    this.textChanged$.pipe(
      debounceTime(600),
      takeUntil(this.componentDestroyed$)
    ).subscribe(() => {
      this.emailObject.markForCheck()
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }
}
