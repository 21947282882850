import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TBackgroundRepeat } from '../interfaces';

@Component({
  selector: 'ip-back-repeat',
  template: `
    <mat-form-field appearance="outline">
      <mat-label i18n="settings|Background Repeat">Repeat</mat-label>
      <mat-select [(value)]="model.repeat" [disabled]="disabled" disableRipple>
        <mat-option *ngFor="let repeat of getRepeats()" [value]="repeat">
          {{ getRepeatLabel(repeat) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      host: {
        display: block;
        width: 100%;
      }
      mat-form-field {
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackRepatComponent {
  @Input() model: { repeat: TBackgroundRepeat };
  @Input() disabled = false;

  private repeatLabels = new Map([
    ['no-repeat', 'No Repeat'],
    ['repeat', 'Repeat'],
    ['repeat-x', 'Repeat X'],
    ['repeat-y', 'Repeat Y'],
    ['round', 'Round'],
    ['space', 'Space']
  ]);

  getRepeats(): TBackgroundRepeat[] {
    return ['no-repeat', 'repeat', 'repeat-x', 'repeat-y'];
  }

  getRepeatLabel(repeat: TBackgroundRepeat): string {
    return this.repeatLabels.get(repeat);
  }
}
