// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://mail.njeasites.net/',
  imagePath: 'https://mail.njeasites.net/api/revolt/upload.php',
  imageGetPath: 'https://mail.njeasites.net/api/revolt/get_images.php',
  convertorPath: 'https://devconverter.njeasites.net/',
  apiKey: 'TlrjJfVpgf38ZuS88mFQltsHLukwiet78HTbuQ17'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
