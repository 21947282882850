import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {SendyService} from './api/sendy.service';
import {map, take} from 'rxjs/operators';
import { TitleService } from '../services/title.service';
import { deferOf } from '../../../core-email-builder/src/public_api';

@Injectable()
export class TitleInterceptor implements CanActivate {
  constructor(private injector: Injector) { 
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const titleService = this.injector.get(TitleService);

    let titleRaw = '';
    if (next.paramMap.has("itemType")) {
      titleRaw = `New ${next.paramMap.get("itemType")}`;
    }
    else {
      titleRaw = next.url[0].path;
    }

    // Titlecase
    // Split the string on spaces
    // Capitalize the first letter of each word and concatenate with rest of word
    // Join back together with spaces
    const title = `:: ${
      titleRaw.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
    }`;
    
    titleService.setPageTitle(title);

    return deferOf(true);
  }
}
