import { InjectionToken } from '@angular/core';

import { IForRootConf, IStructure } from './interfaces';
import { Structure } from './classes/Structure';
import { TextBlock, ImageBlock, ButtonBlock, DividerBlock, SpacerBlock, SocialBlock, TBlocks } from './classes/Elements';

export const IP_DEFAULT_SOCIAL_NETWORKS: string[] = [
  'github',
  'instagram',
  'web',
  'snapchat',
  'youtube',
  'vimeo',
  'medium',
  'soundcloud',
  'dribbble',
  'facebook',
  'twitter',
  'pinterest',
  'linkedin',
  'tumblr',
  'xing'
];
export const IP_SOCIAL_NETWORKS = new InjectionToken<typeof IP_DEFAULT_SOCIAL_NETWORKS>('IP Social Networks', {
  providedIn: 'any',
  factory: () => IP_DEFAULT_SOCIAL_NETWORKS
});

export const IP_DEFAULT_GOOGLE_FONTS: string[] = [
  'Open+Sans:300,400,500,700',
  'Droid+Sans:300,400,500,700',
  'Lato:300,400,500,700',
  'Roboto:300,400,700,900',
  'Ubuntu:300,400,500,700',
  'Fira+Sans:300,400,500,700',
  'Mansalva:400'
];
export const IP_GOOGLE_FONTS = new InjectionToken<typeof IP_DEFAULT_GOOGLE_FONTS>('IP Google Fonts', {
  providedIn: 'any',
  factory: () => IP_DEFAULT_GOOGLE_FONTS
});

export const IP_DEFAULT_FALLBACK_FONTS: string[] = [
  'Palatino Linotype, Book Antiqua, Palatino, serif',
  'Times New Roman, Times, serif',
  'Arial, Helvetica, sans-serif',
  'Arial Black, Gadget, sans-serif',
  'Comic Sans MS, cursive, sans-serif',
  'Impact, Charcoal, sans-serif',
  'Lucida Sans Unicode, Lucida Grande, sans-serif',
  'Tahoma, Geneva, sans-serif',
  'Trebuchet MS, Helvetica, sans-serif',
  'Verdana, Geneva, sans-serif',
  'Courier New, Courier, monospace',
  'Lucida Console, Monaco, monospace'
];
export const IP_FALLBACK_FONTS = new InjectionToken<typeof IP_DEFAULT_FALLBACK_FONTS>('IP Fallback Fonts', {
  providedIn: 'any',
  factory: () => IP_DEFAULT_FALLBACK_FONTS
});

export const IP_DEFAULT_STRUCTURES: IStructure[] = [
  new Structure('cols_1'),
  new Structure('cols_2'),
  new Structure('cols_3'),
  new Structure('cols_4'),
  new Structure('cols_12'),
  new Structure('cols_21')
];
export const IP_STRUCTURES = new InjectionToken<typeof IP_DEFAULT_STRUCTURES>('IP Structures', {
  providedIn: 'any',
  factory: () => IP_DEFAULT_STRUCTURES
});

export const IP_DEFAULT_BLOCKS: TBlocks[] = [
  new TextBlock(),
  new ImageBlock(),
  new ButtonBlock(),
  new DividerBlock(),
  new SpacerBlock(),
  new SocialBlock()
];
export const IP_BLOCKS = new InjectionToken<typeof IP_DEFAULT_BLOCKS>('IP Blocks', {
  providedIn: 'any',
  factory: () => IP_DEFAULT_BLOCKS
});

export const IP_DEFAULT_CONFIG: IForRootConf = Object.freeze({
  xApiKey: 't7HdQfZjGp6R96fOV4P8v18ggf6LLTQZ1puUI2tz',
  // apiPath: 'https://ngb-api.wlocalhost.org/v1',
  useSaveButton: false,
  usePreviewButton: true,
  useImportButton: false,
  useDownloadButton: true,
  templateListIfEmpty: true,
  uploadImageName: 'image',
});
export const IP_CONFIG = new InjectionToken<typeof IP_DEFAULT_CONFIG>('IP Builder Configuration', {
  providedIn: 'root',
  factory: () => IP_DEFAULT_CONFIG
});

export const IP_STORAGE_FACTORY = new InjectionToken<Storage>('IP Storage factory token', {
  providedIn: 'any',
  factory: () => sessionStorage,
});

export const IP_TEMPLATES_TEMPORARY_STORAGE = new InjectionToken<Storage>('IP Token that stores temporary templates cache', {
  providedIn: 'any',
  factory: () => sessionStorage,
});
