<ng-container *ngIf="generalOptions$ | async as general">
  <div class="mat-expansion-panel-body" style="margin: 24px 0">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label i18n="General Settings|Email Name">Email Name</mat-label>
      <input matInput [(ngModel)]="general.name">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label i18n="General Settings|Email Preview Text">Preview Text</mat-label>
      <textarea matInput [(ngModel)]="general.previewText"></textarea>
      <mat-hint i18n="General Settings|Email Preview Text Hint">
        This text is displayed in the inbox of the recipient.
      </mat-hint>
    </mat-form-field>
  </div>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header i18n="General Settings|Email Background">
        Background
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-color [options]="general.background"></ip-color>
        <!-- <ip-image-upload [block]="structure.options.background" key="url" style="margin-bottom: 1em"></ip-image-upload>
        <ip-back-repeat [disabled]="!structure.options.background.url" [model]="structure.options.background">
        </ip-back-repeat>
        <ip-width-height [model]="structure.options.background.size" label="Background Size"></ip-width-height> -->
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header i18n="General Settings|Email Attributes">
        Attributes
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-width-height [model]="general.width" label="width"></ip-width-height>
        <ip-direction [model]="general"></ip-direction>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header i18n="General Settings|Email Padding">
        Padding
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-padding [padding]="general.padding"></ip-padding>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
