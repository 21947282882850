<div class="table-container">
    <mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
      <mat-cell *matCellDef="let template">{{template.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell class="description-cell" (click)="editTemplate(template.id)"
          *matCellDef="let template">{{template.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
      <mat-cell *matCellDef="let template"> <button mat-raised-button color="primary" (click)="editTemplate(template.id)">Edit</button> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
      <mat-cell *matCellDef="let template"> <button mat-raised-button color="primary" (click)="deleteTemplate(template.id, template.name)">Delete</button> </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>
</div>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 20, 50]" showFirstLastButtons></mat-paginator>
