<h1 mat-dialog-title>Test Campaign</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="Email" [(ngModel)]="emailAddress">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="emailAddress" cdkFocusInitial>Ok</button>
</div>
