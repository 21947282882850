<div fxLayout="column" style="height: 100%;">
    <h2 mat-dialog-title i18n="image-gallery|Modal Image gallery header">Image Gallery</h2>
    <div class="mat-typography gallery-list-wrapper" fxLayout fxLayoutGap="1rem" fxFlex>
        <mat-list fxFlex="200px">
            <mat-list-item (click)="changeCurrentMenuItem('gallery')" [class.active]="currentMenuItem === 'gallery'"
                i18n="image-gallery|Image list">
                Gallery Image list
            </mat-list-item>
            <mat-list-item (click)="changeCurrentMenuItem('upload')" [class.active]="currentMenuItem === 'upload'"
                i18n="image-gallery|Upload an image">
                Upload an image
            </mat-list-item>
        </mat-list>

        <ng-container *ngIf="currentMenuItem === 'gallery'">
            <ng-container *ngIf="imageCategoryList.length;else emptyImageList">
                <cdk-virtual-scroll-viewport autosize fxFlex
                    class="gallery-list">
                    <div *cdkVirtualFor="let imagePath of imageCategoryList" class="gallery-list-item"
                        (click)="choosedImage = imagePath" i18n-matTooltip="image-gallery|Click to choose message"
                        matTooltip="Click to choose">
                        <img [src]="imagePath" />
                    </div>
                </cdk-virtual-scroll-viewport>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="currentMenuItem === 'upload'">
            <div class="upload-image" fxLayout="column" fxLayoutAlign="center center" fxFlex fxLayoutGap="1rem">
                <label class="upload-image-item" [class.empty]="!uploadImagePreview">
                    <div class="upload-image-item-overview" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon>backup</mat-icon>
                    </div>
                    <img class="upload-image-item-preview" *ngIf="uploadImagePreview" [src]="uploadImagePreview" />
                    <input type="file" fxHide pattern="image/*" (change)="previewImage($event)">
                </label>
                <button mat-flat-button color="primary" (click)="startUploading()">Upload and insert into
                    template</button>
            </div>
        </ng-container>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close i18n="actions|Undo">Cancel</button>
        <button mat-stroked-button color="primary" [disabled]="!choosedImage" (click)="addImageToTemplate()"
            i18n="actions|Start">
            Add to template
        </button>
    </mat-dialog-actions>
</div>


<ng-template #emptyImageList>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="empty-category-notification">
        <mat-icon>notifications</mat-icon>
        <h2 i18n="image-gallery|Image list is empty message">Image list is empty!</h2>
    </div>
</ng-template>