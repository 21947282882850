
import { EmailList } from "src/modals/save-as-modal/EmailListData";

import { Template } from "./template";

export interface Campaign extends Template {
    fromEmail : string,
    fromName : string,
    replyTo: string,
    isSent: boolean,
    sendDate: Date,
}


export function isCampaign(value: Template | Campaign): value is Campaign {
    return value.hasOwnProperty('replyTo');
}