import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { catchError, finalize, switchMap, shareReplay, tap } from 'rxjs/operators';

import { IForRootConf, IMjmlServerResponse, IUserModule, IUserTemplate } from './interfaces';
import { IPEmail } from './classes/DefaultEmail';

import { IP_CONFIG, IP_DEFAULT_CONFIG } from './tokens';
import { IpEmailObjectStoreService } from './ip-email-object-store.service';
import { IpUserMiddlewaresService } from './user-middleware-service/ip-middlewares.service';
import { IpUserInterfaceService } from './user-interface.service';

@Injectable()
export class IpEmailBuilderService {
  // private _template = new BehaviorSubject<string>('');
  // private _mjml = new BehaviorSubject<string>('');
  // private readonly _onSave$ = new Subject();

  /**
   * @deprecated Please use the new service "UserRestApiService" instead
   */
  public modules = new Set<IUserModule>();
  /**
   * @deprecated Please use the new service "UserRestApiService" instead
   */
  public readonly customTemplates = new Set<IUserTemplate>();
  /**
  * @deprecated Please use the new service "UserRestApiService" instead
  */
  private _mergeTags = new Set<string>();

  public readonly onTemplateCreated$ = this.emailObjectStore.onTemplateCreated$.pipe(shareReplay());

  /**
   * @deprecated Use onTemplateCreated$ instead
   */
  public readonly onSave$ = this.onTemplateCreated$;


  /**
   * @deprecated Use isLoading$ instead
   */
  public readonly isLoading = this.ipUserInterface.activeMatProgress$.asObservable();
  public readonly isLoading$ = this.ipUserInterface.activeMatProgress$.asObservable();

  constructor(
    @Inject(IP_CONFIG) public config: IForRootConf,
    // @Inject(IP_GOOGLE_FONTS) private googleFonts: string[],
    private emailObjectStore: IpEmailObjectStoreService,
    private ipMiddleWares: IpUserMiddlewaresService,
    private ipUserInterface: IpUserInterfaceService,
    // private _http: HttpClient,
  ) {
    this.config = { ...IP_DEFAULT_CONFIG, ...this.config };
  }

  /**
   * Save current Email Template
   */
  createHTMLTemplate$() {
    this.ipUserInterface.activeMatProgress$.next(true);
    return this.emailObjectStore.createHTMLTemplate$().pipe(
      finalize(() => this.ipUserInterface.activeMatProgress$.next(false)),
      catchError((error: HttpErrorResponse) => this.ipMiddleWares.catchError(error).pipe(
        switchMap(() => {
          this.ipUserInterface.notify(error.message);
          return EMPTY;
        })
      ))
    );
  }

  /**
  * Generate HTML 
  */
  createHTMLTemplateRaw(email: IPEmail) : Promise<IMjmlServerResponse> {
    this.ipUserInterface.activeMatProgress$.next(true);
    return this.emailObjectStore.createHTMLTemplateRaw(email).then((response) =>  {
      this.ipUserInterface.activeMatProgress$.next(false);
      return response;
    });
  }

  /**
   *  @deprecated Please use ngb.createHTMLTemplate$() instead
   */
  saveEmail() {
    return this.createHTMLTemplate$();
  }

  /**
   * Get Email as object
   */
  get Email() {
    return this.emailObjectStore.Email;
  }

  /**
   * Set Email saved from database or created with new IPEmail()
   */
  set Email(newEmail: IPEmail) {
    this.emailObjectStore.setEmail(newEmail);
  }

  /**
   * @deprecated Use getTemplateAsObservale$() instead
   */
  get Template() {
    return null;
  }
  /**
   * Set HTML output
   */
  // set Template(template: string) {
  //   this._template.next(template);
  // }

  /**
   * @deprecated Use getMjmlAsObservable$() instead
   */
  get Mjml() {
    return null;
  }

  /**
   * Listen Email changes
   */
  getEmailAsObservalble$(): Observable<IPEmail> {
    return this.emailObjectStore.emailAsObservable$;
  }

  /**
   * Listen Template changes
   */
  getTemplateAsObservale$(): Observable<string> {
    return this.emailObjectStore.templateAsObservable$;
  }

  /**
   * Listen MJML changes
   */
  getMjmlAsObservable$() {
    return this.emailObjectStore.mjmlAsObservable$;
  }

  /**
   * Listen Email and Template chabges
   * @deprecated Use onTemplateCreated$ instead
   */
  onChanges$(): Observable<[IPEmail, string, string]> {
    return this.onTemplateCreated$;
  }

  /**
   * Get Set of Structures from aside
   */
  // getSideStructures(): Set<IStructure> {
  //   return this._structures;
  // }

  /**
   * Get Set of Blocks from aside
   */
  // getSideBlocks(): Set<TBlocks> {
  //   return this._blocks;
  // }

  /**
   * Get a Set of fonts family
   */
  // get Fonts(): IFontFamily {
  //   return this._fontFamily;
  // }
  // set Fonts(fonts: IFontFamily) {
  //   this._fontFamily = fonts;
  // }

  // get FallbackFonts(): IFontFamily {
  //   return this._fallbackFamily;
  // }
  // set FallbackFonts(fonts: IFontFamily) {
  //   this._fallbackFamily = fonts;
  // }

  /**
  * @deprecated Please use the new service "UserRestApiService" instead
  */
  // get MergeTags(): Set<string> {
  //   return this._mergeTags;
  // }

  // set MergeTags(tags: Set<string>) {
  //   this._mergeTags = tags;
  // }
}
