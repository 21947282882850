<mat-card>
  <mat-card-header>
    <mat-card-title>Total Emails Sent</mat-card-title>
  </mat-card-header>
  <mat-card-content><p>{{dataSource.total}}</p></mat-card-content>
</mat-card>
<mat-card>
  <mat-card-header>
    <mat-card-title>Emails Sent This Month</mat-card-title>
  </mat-card-header>
  <mat-card-content><p>{{dataSource.current}}</p></mat-card-content>
</mat-card>
