import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private pageTitleSource = new BehaviorSubject('');
  public pageTitle$ = this.pageTitleSource.asObservable();

  public setPageTitle(title: string): void {
    this.pageTitleSource.next(title);
  }
}
