<mat-accordion *ngIf="currentStructure$ | async as structure">
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header i18n="Structure settings|Change structure Background">
      Background
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ip-color [options]="structure.options.background"></ip-color>
      <ip-image-upload [block]="structure.options.background" key="url" style="margin-bottom: 1em"></ip-image-upload>
      <ip-back-repeat [disabled]="!structure.options.background.url" [model]="structure.options.background">
      </ip-back-repeat>
      <ip-width-height [disabled]="!structure.options.background.url" [model]="structure.options.background.size"
        label="size">
      </ip-width-height>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header i18n="Structure settings|Change structure border">
      Border
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ip-border [border]="structure.options.border"></ip-border>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header i18n="Structure settings|Change structure padding">
      Padding
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ip-padding [padding]="structure.options.padding"></ip-padding>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header i18n="Structure settings|Change structure margin">
      Margin
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ip-margin [margin]="structure.options.margin"></ip-margin>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header i18n="Structure settings|Change structure layout">
      Layout
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ip-gaps [gaps]="structure.options.gaps"></ip-gaps>
      <mat-slide-toggle [disabled]="structure.type === 'cols_1'" style="margin-top: -24px;"
        [(ngModel)]="structure.options.disableResponsive" i18n="Structure settings|Disable Responsive"
        (change)="markForCheck()">
        Disable Responsive
      </mat-slide-toggle>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel *ngFor="let column of structure.options.columns; let key = index">
    <mat-expansion-panel-header>
      <ng-container i18n="Structure settings|Structure Column">Column</ng-container> {{ key + 1 }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <h3 class="divider">
        <span i18n="Structure settings|Column Background">Column Background</span>
      </h3>
      <ip-color [options]="column.background" [showTransparent]="true"></ip-color>
      <h3 class="divider">
        <span i18n="Structure settings|Column Border">Column Border</span>
      </h3>
      <ip-border [border]="column.border"></ip-border>
      <h3 class="divider">
        <span i18n="Structure settings|Column Vertical Align">Vertical Align</span>
      </h3>
      <ip-align [model]="column" mode="vertical"></ip-align>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
