<ng-content select=".move"></ng-content>
<div class="tools" fxLayout fxLayoutGap="0.25rem">
  <button mat-icon-button (click)="duplicateBlock()" i18n-matTooltip="block|Duplicate Block"
    matTooltip="Duplicate Block">
    <mat-icon i18n-aria-label="block|Duplicate Block" aria-label="Duplicate block" inline>file_copy</mat-icon>
  </button>
  <button mat-icon-button (click)="removeBlock()" i18n-matTooltip="block|Remove Block" class="delete"
    matTooltip="Remove Block">
    <mat-icon i18n-aria-label="block|Remove Block" aria-label="Delete block" inline>delete_forever</mat-icon>
  </button>
</div>
<ng-content *ipDynamicComponent="block"></ng-content>
