import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IAddStructureEvent } from '../../../../core-email-builder/src/lib/user-middleware-service/ip-middlewares.service';
import { IpEmailObjectStoreService } from '../../../../core-email-builder/src/lib/ip-email-object-store.service';

@Component({
  selector: 'app-footer-validation-modal',
  templateUrl: './footer-validation-modal.component.html',
  styleUrls: ['./footer-validation-modal.component.scss']
})

export class FooterValidationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FooterValidationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private emailObjectStore: IpEmailObjectStoreService
  ) {
   }

  ngOnInit(): void {

    
  }

  revert() : void {

    this.dialogRef.close("reload");
  }
  footer() : void {
    const event: IAddStructureEvent = JSON.parse('{ "currentIndex": 999, "item": { "data": { "type": "cols_1", "elements": [ [ { "innerText": "<p class=\'ql-align-center\'>Address: enter address | Phone: enter phone</p>", "state": { "disabled": false, "message": "" }, "type": "text", "icon": "text_format", "options": { "font": { "family": "Roboto", "size": 16, "fallback": "Arial, Helvetica, sans-serif" }, "color": "#000000", "padding": { "top": 10, "left": 25, "right": 25, "bottom": 10 }, "lineHeight": { "unit": "px", "value": 40 } } }, { "state": { "disabled": false, "message": "" }, "type": "divider", "icon": "remove", "options": { "border": { "color": "#909090", "style": "solid", "width": 1 }, "padding": { "top": 10, "left": 25, "right": 25, "bottom": 10 } } }, { "innerText": "<p class=\'ql-align-center\'><a href=\'[webversion]\' rel=\'noopener noreferrer\' target=\'_blank\'>view on the web</a></p><p id=\'njea-unsubscribe\' class=\'ql-align-center\'><a href=\'[unsubscribe]\' rel=\'noopener noreferrer\' target=\'_blank\'>unsubscribe</a></p>", "state": { "disabled": false, "message": "" }, "type": "text", "icon": "text_format", "options": { "font": { "size": 16, "family": "Roboto", "fallback": "Arial, Helvetica, sans-serif" }, "color": "#000000", "padding": { "top": 10, "left": 25, "right": 25, "bottom": 10 }, "lineHeight": { "unit": "px", "value": 40 } } } ] ], "columns": 1, "id": 1719236315613, "options": { "border": { "color": "#cccccc", "style": "solid", "width": 0, "radius": 0 }, "background": { "color": "#ffffff", "url": "", "repeat": "repeat", "size": { "value": 100, "unit": "px", "auto": true, "units": [ "px", "%", "cover", "contain" ] } }, "padding": { "top": 4, "right": 4, "bottom": 4, "left": 4 }, "margin": { "top": 0, "bottom": 0 }, "gaps": [ 4, 4 ], "columns": [ { "background": { "color": "transparent" }, "border": { "width": 0, "color": "#cccccc", "radius": 0, "style": "solid" }, "verticalAlign": "top" } ], "columnsWidth": [ 1 ] } } } }');
    this.emailObjectStore.addStructure(event);
    this.dialogRef.close();
  }
  custom() : void {
    const event: IAddStructureEvent = JSON.parse('{ "currentIndex": 999, "item": { "data": { "type": "cols_1", "elements": [ [ { "innerText": "<p id=\'njea-unsubscribe\' class=\'ql-align-center\'><a href=\'[unsubscribe]\' rel=\'noopener noreferrer\' target=\'_blank\'>unsubscribe</a></p>", "state": { "disabled": false, "message": "" }, "type": "text", "icon": "text_format", "options": { "font": { "family": "Roboto", "size": 16, "fallback": "Arial, Helvetica, sans-serif" }, "color": "#000000", "padding": { "top": 10, "left": 25, "right": 25, "bottom": 10 }, "lineHeight": { "unit": "px", "value": 40 } } } ] ], "columns": 1, "id": 1718376077618, "options": { "border": { "color": "#cccccc", "style": "solid", "width": 0, "radius": 0 }, "background": { "color": "#ffffff", "url": "", "repeat": "repeat", "size": { "value": 100, "unit": "px", "auto": true, "units": [ "px", "%", "cover", "contain" ] } }, "padding": { "top": 4, "right": 4, "bottom": 4, "left": 4 }, "margin": { "top": 0, "bottom": 0 }, "gaps": [ 4, 4 ], "columns": [ { "background": { "color": "transparent" }, "border": { "width": 0, "color": "#cccccc", "radius": 0, "style": "solid" }, "verticalAlign": "top" } ], "columnsWidth": [ 1 ] } } } }');
    this.emailObjectStore.addStructure(event);
    this.dialogRef.close();
  }
}