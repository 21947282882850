import { MatDialog } from '@angular/material/dialog';
import { switchMap, exhaustMap, filter } from 'rxjs/operators';

import { IpUserImageUploaderService } from './user-image-uploader.service';
import { IpUserMiddlewaresService } from '../user-middleware-service/ip-middlewares.service';
import { UploadImageGalleryComponent } from './upload-image-gallery/upload-image-gallery.component';
import { IpUserRestApiService } from '../user-rest-api-service/user-rest-api.service';

export default class PaidUsersImageUploaderServiceService extends IpUserImageUploaderService {
  constructor(
    private matDialog: MatDialog,
    private userMiddleware: IpUserMiddlewaresService,
    private userRestApi: IpUserRestApiService
  ) {
    super();
  }
  browse$() {
    return this.userRestApi.getUserImages$().pipe(
      exhaustMap(imageCategoryList => this.matDialog.open(UploadImageGalleryComponent, {
        data: imageCategoryList,
        width: '60vw', maxWidth: '800px',
        height: '60vh', maxHeight: '800px'
      }).afterClosed()),
      filter(path => !!path.length),
      switchMap(path => this.userMiddleware.uploadImage(path))
    );
  }
}
