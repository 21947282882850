<mat-sidenav-container>
  <div class="ip-builder-container" fxLayout="column" fxFlex>
    <mat-progress-bar *ngIf="activeMatProgress$ | async" mode="buffer"></mat-progress-bar>
    <mat-toolbar>
      <ng-content fxFlex="fill" select=".top-actions"></ng-content>

      <div fxFlexlayout fxLayoutGap="0.5rem">
        <button *ngIf="ngb.config.useSaveButton" type="button" (click)="saveEmail()" mat-stroked-button color="primary"
          i18n="actions|Save Email">
          Save Email
        </button>
        <button *ngIf="ngb.config.usePreviewButton" type="button" (click)="togglePreview()" mat-stroked-button>
          <ng-container [ngSwitch]="previewTemplate">
            <span *ngSwitchDefault i18n="actions|Preview">Preview</span>
            <span *ngSwitchCase="true" i18n="actions|Close Preview">Close Preview</span>
          </ng-container>
        </button>
 
        <button *ngIf="ngb.config.useImportButton" mat-button mat-stroked-button i18n="actions|Import" (click)="importFile()">
          Import
        </button>

        <ng-container *ngIf="ngb.config.useDownloadButton">
          <button mat-button [matMenuTriggerFor]="downloadMenu" mat-stroked-button i18n="actions|Export">
            Export
          </button>
          <mat-menu #downloadMenu="matMenu">
            <button mat-menu-item (click)="download('html')" i18n="actions|Export HTML">
              Export HTML
            </button>
            <button mat-menu-item (click)="download('mjml')" i18n="actions|Export MJML">
              Export MJML
            </button>
            <button mat-menu-item (click)="download('json')" i18n="actions|Export JSON">
              Export JSON
            </button>
          </mat-menu>
        </ng-container>

      </div>
      <!-- <div class="history-actions" style="margin-inline-start: auto;">
        <span>Last saved Now</span>
        <button type="button" mat-icon-button>
          <mat-icon>undo</mat-icon>
        </button>
        <button type="button" mat-icon-button>
          <mat-icon>redo</mat-icon>
        </button>
      </div> -->
    </mat-toolbar>
    <ng-container [ngSwitch]="previewTemplate">
      <ip-builder-container *ngSwitchDefault fxFlex fxLayout fxLayoutAlign="center start"
        [ngStyle]="getBuilderContainerStyles$ | async">
        <ng-content select=".rewrited-empty-email"></ng-content>
      </ip-builder-container>

      <ip-preview-template fxLayout fxFlex="auto" fxLayoutAlign="center" [template]="currentHTMLTemplate$ | async"
        *ngSwitchCase="true">
      </ip-preview-template>
    </ng-container>
  </div>
  <mat-sidenav mode="side" fxFlex="0 0 310px" [opened]="!previewTemplate" position="end" class="ip-builder-content">
    <mat-tab-group [selectedIndex]="currentTabIndex$ | async" (selectedIndexChange)="changeTabIndex($event)">
      <mat-tab i18n-label="Content" label="Content">
        <div class="main-padding">
          <cdk-drop-list class="elements" [cdkDropListConnectedTo]="cdkDropListConnectedTo$ | async"
            [cdkDropListData]="blocks" [cdkDropListSortingDisabled]="true" id="block-elements">
            <div cdkDrag *ngFor="let block of blocks; trackBy: trackByFn" [cdkDragData]="block"
              [cdkDragDisabled]="disableBlocksList$(block) | async" class="drag-element"
              [matTooltip]="block.state.message">
              <mat-icon>{{ block.icon }}</mat-icon>
              <div class="drag-element-title" i18n="blocks|Blocks list name">{{ block.type }}</div>
            </div>
          </cdk-drop-list>

          <mat-divider style="margin: 1em 0;"></mat-divider>
          <!-- <h3 class="divider"><span>Structures</span></h3> -->
          <cdk-drop-list class="elements structure-elements" cdkDropListConnectedTo="structures-drop-list"
            [cdkDropListSortingDisabled]="true" [cdkDropListData]="structures">
            <div cdkDrag *ngFor="let structure of structures; trackBy: trackByFn" [cdkDragData]="structure"
              [cdkDragDisabled]="disableStructureList$(structure) | async" class="drag-element structure-element"
              [ngClass]="structure.type">
              <div *ngFor="let column of createArrayFromStructureColumns(structure)"></div>
            </div>
          </cdk-drop-list>
          <ng-content fxLayout fxLayoutGap="0.5rem" select=".after-structure-blocks"></ng-content>
        </div>
      </mat-tab>
      <mat-tab i18n-label="Modules" label="Modules" class="modules-tab">
        <cdk-virtual-scroll-viewport autosize
          style="height: inherit;" *ngIf="customModuleList$ | async as modules; else emptyModuleList">
          <div class="main-padding">
            <div class="custom-modules" cdkDropList cdkDropListConnectedTo="structures-drop-list"
              [cdkDropListData]="modules" [cdkDropListSortingDisabled]="true">
              <div fxLayout="column" fxLayoutGap="1rem">
                <ng-container *cdkVirtualFor="let module of modules">
                  <div cdkDrag [cdkDragData]="module.module"
                    [cdkDragDisabled]="disableStructureList$(module.module) | async" class="drag-element"
                    style="padding: 1em;text-transform: uppercase;height: 54px;">
                    <span *ngIf="module.name" i18n="modules|module name">{{module.name}}</span>
                    <!-- <img *ngIf="module.thumb" [src]="module.thumb" /> -->
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-content fxLayout fxLayoutGap="0.5rem" select=".after-content-blocks"></ng-content>
          </div>
        </cdk-virtual-scroll-viewport>
        <ng-template #emptyModuleList>
          <h4 class="main-padding" style="text-align: center;" i18n="modules|empty list message">
            Module list is empty
          </h4>
        </ng-template>
      </mat-tab>
      <mat-tab i18n-label="Settings" label="Settings">
        <div class="ip-builder-options" [ngSwitch]="showGeneralSettings$ | async">
          <ip-general-settings *ngSwitchDefault></ip-general-settings>
          <ip-block-settings *ngSwitchCase="'block'"></ip-block-settings>
          <ip-structure-settings *ngSwitchCase="'structure'" fxLayout="column"></ip-structure-settings>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>
</mat-sidenav-container>