<ng-container *ngIf="block$ | async as currentBlock">
  <mat-accordion>
    <mat-expansion-panel expanded *ngIf="hasProperty(currentBlock.options, 'font')">
      <mat-expansion-panel-header i18n="settings|Font">
        Font
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-color [options]="currentBlock.options"></ip-color>
        <ip-font-styles [font]="currentBlock.options.font"></ip-font-styles>
        <ip-line-height *ngIf="currentBlock.type !== 'social'" [lineHeight]="currentBlock.options.lineHeight">
        </ip-line-height>
      </ng-template>
    </mat-expansion-panel>

    <ng-container *ngIf="currentBlock.type === 'image'">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header i18n="settings|Image">
          Image
        </mat-expansion-panel-header>
        <ip-image-upload [block]="currentBlock"></ip-image-upload>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header i18n="settings|Image Attributes">
          Attributes
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="group f-large">
            <mat-form-field appearance="outline">
              <mat-label i18n="settings|Image Alt">Alt</mat-label>
              <input matInput [(ngModel)]="currentBlock.options.title" type="text" i18n-placeholder
                placeholder="settings|Image Alt" (input)="markForCheck()" />
            </mat-form-field>
            <ip-align [model]="currentBlock.options"></ip-align>
          </div>
          <ip-link [link]="currentBlock.options.link"></ip-link>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="currentBlock.type === 'button'">
      <mat-expansion-panel>
        <mat-expansion-panel-header i18n="settings|Button Text">
          Button Text
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label i18n="settings|Button Text">Button Text</mat-label>
            <input matInput [(ngModel)]="currentBlock.innerText" type="text" i18n-placeholder
              placeholder="settings|Button Text" (input)="markForCheck()" />
          </mat-form-field>
        </ng-template>
      </mat-expansion-panel>


      <mat-expansion-panel>
        <mat-expansion-panel-header i18n="settings|Background">
          Background
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <ip-color [options]="currentBlock.options"></ip-color>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header i18n="settings|Button Attributes">
          Attributes
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="group two" style="padding-bottom: 0">
            <ip-align [model]="currentBlock.options" [disabled]="currentBlock.options.fullWidth"></ip-align>
            <mat-slide-toggle style="margin-top: -24px" [(ngModel)]="currentBlock.options.fullWidth"
              (change)="markForCheck()">
              <ng-container i18n="settings|Button Full Width">Full Width</ng-container>
            </mat-slide-toggle>
          </div>
          <ip-link [link]="currentBlock.options.link"></ip-link>
          <ip-padding [padding]="currentBlock.options.innerPadding"></ip-padding>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel [expanded]="currentBlock.type === 'spacer'"
      *ngIf="hasProperty(currentBlock.options, 'width') || hasProperty(currentBlock.options, 'height')">
      <mat-expansion-panel-header i18n="settings|Sizes">
        Sizes
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-width-height *ngIf="hasProperty(currentBlock.options, 'width')" [model]="currentBlock.options.width"
          label="width"></ip-width-height>
        <ip-width-height *ngIf="hasProperty(currentBlock.options, 'height')" [model]="currentBlock.options.height"
          label="height"></ip-width-height>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="hasProperty(currentBlock.options, 'background')">
      <mat-expansion-panel-header i18n="settings|Background">
        Background
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-color [options]="currentBlock.options.background"></ip-color>
      </ng-template>
      backgroundColor
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="currentBlock.type === 'divider'"
      *ngIf="hasProperty(currentBlock.options, 'border')">
      <mat-expansion-panel-header>
        <ng-container i18n="settings|Divider Styles" *ngIf="currentBlock.type === 'divider'">Styles</ng-container>
        <ng-container i18n="settings|Border" *ngIf="currentBlock.type !== 'divider'">Border</ng-container>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-border [border]="currentBlock.options.border"></ip-border>
      </ng-template>
    </mat-expansion-panel>

    <ng-container *ngIf="currentBlock.type === 'social'">
      <mat-expansion-panel>
        <mat-expansion-panel-header i18n="settings|Icons">
          Icons
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label i18n="settings|Add new Network">Add new Network</mat-label>
            <mat-select (selectionChange)="addSocialNetwork($event, currentBlock)" [disabled]="!socialNetworks.length">
              <mat-option style="text-transform: capitalize" *ngFor="let link of socialNetworks(currentBlock)"
                [value]="link">
                {{ link }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="networks-list" *ngFor="let network of currentBlock.networks">
        <mat-expansion-panel-header style="text-transform: capitalize">
          <img [class]="network.name" [src]="getSocialIcon(network.name)" [alt]="network.name" />
          {{ network.name }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="networks-list-item">
            <div class="item-info">
              <mat-form-field matLine appearance="outline">
                <mat-label i18n="settings|Social Network Label">Label</mat-label>
                <input matInput autocomplete="off" [(ngModel)]="network.label"
                  i18n-placeholder="settings|Social Network Label" placeholder="Label" type="text"
                  (input)="markForCheck()" />
              </mat-form-field>
              <button mat-stroked-button color="warn" (click)="removeSocialNetwork(network, currentBlock)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
            <mat-form-field matLine appearance="outline">
              <mat-label style="text-transform: capitalize">
                {{ network.name }} <ng-container i18n="settings|Social Network Link">Link</ng-container>
              </mat-label>
              <input autocomplete="off" matInput [(ngModel)]="network.href" type="url" (input)="markForCheck()" />
            </mat-form-field>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header i18n="settings|Social Styles">
          Styles
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="group two" style="padding-bottom: 0">
            <mat-form-field appearance="outline">
              <mat-label i18n="settings|Social Model">Mode</mat-label>
              <mat-select (selectionChange)="markForCheck()" i18n-placeholder="settings|Social Model" placeholder="Mode"
                [(value)]="currentBlock.options.mode" disableRipple>
                <mat-option *ngFor="let mode of ['horizontal', 'vertical']" [value]="mode">
                  {{ mode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ip-align [model]="currentBlock.options"></ip-align>
          </div>
          <ip-line-height [units]="['px']" [lineHeight]="currentBlock.options.iconSize" label="Icon Size">
          </ip-line-height>
          <ip-padding [padding]="currentBlock.options.innerPadding"></ip-padding>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel *ngIf="hasProperty(currentBlock.options, 'padding')">
      <mat-expansion-panel-header i18n="settings|Padding">
        Padding
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ip-padding [padding]="currentBlock.options.padding"></ip-padding>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>