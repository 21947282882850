import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { take, catchError, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { IpUserMiddlewaresService } from '../../user-middleware-service/ip-middlewares.service';
import { IPEmail } from '../../classes/DefaultEmail';

@Component({
  selector: 'ip-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit, OnDestroy {
  @ViewChild('uploadInput', { read: ElementRef, static: true })
  private uploadInput: ElementRef<HTMLInputElement>

  public importing = false
  private fileReader = new FileReader()

  constructor(
    private ipMiddlewares: IpUserMiddlewaresService,
    private bottomSheetRef: MatBottomSheetRef<ImportDialogComponent, IPEmail>,
  ) { }


  openBrowserModal(event: MouseEvent) {
    event.preventDefault()
    this.uploadInput.nativeElement.click()
  }

  startImporting() {
    this.importing = true

    this.ipMiddlewares.importFile(this.uploadInput.nativeElement.files.item(0))
      .pipe(take(1), catchError((error) => this.ipMiddlewares.catchError(error).pipe(
        switchMap(() => {
          this.bottomSheetRef.dismiss(error)
          return EMPTY
        })
      )))
      .subscribe(file => {
        this.fileReader.readAsText(file, 'utf-8')
      })
  }

  ngOnInit() {
    this.fileReader.addEventListener('loadend', () => {
      this.importing = false
      try {
        const email = JSON.parse(this.fileReader.result as string)
        this.bottomSheetRef.dismiss(email)
      } catch (error) {
        this.bottomSheetRef.dismiss(null)
        throw new Error(error)
      }
    })
  }

  ngOnDestroy() {
  }

}
