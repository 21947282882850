import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {SendyService} from '../api/sendy.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private sendyService: SendyService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm){
    this.sendyService.login({
      email: form.value.email,
      password: form.value.password
    }).then(res => {
      if(res){
        this.router.navigate(['/builder', 'campaign']);
      }else{
        alert('failed');
      }
    });
  }
}
