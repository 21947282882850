import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_INITIALIZER} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IpEmailBuilderModule, IpStorageService, IpUserMiddlewaresService, IpUserRestApiService, IP_TEMPLATES_TEMPORARY_STORAGE} from '../../core-email-builder/src/public_api';
import { LoginComponent } from './auth/login/login.component';
import { MaterialModule } from './material.module';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SendyService } from './auth/api/sendy.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { TemplateComponent } from './lists/template/template.component';
import { CampaignComponent } from './lists/campaign/campaign.component';
import { SaveAsModalComponent } from './modals/save-as-modal/save-as-modal.component';
import { FooterValidationModalComponent } from './modals/footer-validation-modal/footer-validation-modal.component';
import { TestModalComponent } from './modals/test-modal/test-modal.component';
import { IpEmailService } from './services/ip-email.service';
import { environment } from './environments/environment';
import { EmailBuilderComponent as EmailBuilderComponent } from './email-builder/email-builder.component';
import { IpTemplateStorageService } from './services/ip-template-storage.service';
import { StatsComponentComponent } from './reports/stats-component/stats-component.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {DatePipe} from '@angular/common';
import { SendModalComponent } from './modals/send-modal/send-modal.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';




export function createUsersIpStorage(templateStorage: Storage) {
  return new IpTemplateStorageService(templateStorage);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TemplateComponent,
    CampaignComponent,
    SaveAsModalComponent,
    FooterValidationModalComponent,
    DeleteModalComponent,
    TestModalComponent,
    StatsComponentComponent,
    EmailBuilderComponent,
    SendModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IpEmailBuilderModule.withConfig(
      {
        xApiKey: environment.apiKey,
        apiPath: environment.convertorPath,
        uploadImagePath: environment.imagePath,
        useSaveButton: false,
        useImportButton: false,
        usePreviewButton: true,
        useDownloadButton: false
      }),
    MaterialModule,
    MatInputModule,
    FlexModule,
    FormsModule,
    HttpClientModule,
    MatGridListModule,
    MatCardModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
  ],
  providers: [DatePipe,
    SendyService,
    {provide: IpUserRestApiService, useClass: IpEmailService},
    {
      provide: IpStorageService, useFactory: createUsersIpStorage,
      deps: [IP_TEMPLATES_TEMPORARY_STORAGE]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
