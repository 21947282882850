<mat-nav-list>
  <mat-list-item (click)="openBrowserModal($event)">
    <div *ngIf="!uploading">
      <span mat-line i18n="upload|Browse images from computer">Browse images from computer</span>
    </div>
    <ng-container *ngIf="uploading">
      <img matListAvatar [src]="previewLink()" />
      <span mat-line i18n="upload|Uploading Message">Uploading ...</span>
      <span mat-line>{{ imageInfo }}</span>
    </ng-container>
  </mat-list-item>
  <mat-progress-bar *ngIf="uploading" mode="buffer" [value]="progress | async"></mat-progress-bar>
</mat-nav-list>
<input type="file" accept="image/*" fxHide #uploadInput (change)="uploadInputChanged()" />
