import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Renderer2,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  OnInit,
  Inject,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subject, iif, EMPTY } from 'rxjs';
import { switchMap, take, takeUntil, filter, catchError, withLatestFrom, exhaustMap, map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IpEmailBuilderService } from './ip-email-builder.service';
import { IStructure } from './interfaces';
import { IPEmail } from './classes/DefaultEmail';
import { SlugifyPipe } from './slugify.pipe';
import { IpUserMiddlewaresService, TExportType } from './user-middleware-service/ip-middlewares.service';
import { TBlocks } from './classes/Elements';
import { IpUserInterfaceService } from './user-interface.service';
import { IpEmailObjectStoreService } from './ip-email-object-store.service';
import { IP_STRUCTURES, IP_BLOCKS, IP_GOOGLE_FONTS } from './tokens';
import { IpUserRestApiService } from './user-rest-api-service/user-rest-api.service';
import { deferOf } from './utils';

@Component({
  selector: 'ip-email-builder',
  templateUrl: './ip-email-builder.component.html',
  styleUrls: ['./ip-email-builder.component.scss'],
  exportAs: 'ipEmailBuilder',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpEmailBuilderComponent),
      multi: true
    },
    SlugifyPipe
  ]
})
export class IpEmailBuilderComponent implements OnChanges, OnDestroy, OnInit, ControlValueAccessor {
  @Input() email?: IPEmail;
  @Output() emailChange = new EventEmitter<IPEmail>();

  previewTemplate = false;
  showGeneralSettings$ = this.userInterfaceService.editGeneralSettings$;
  currentTabIndex$ = this.userInterfaceService.currentTabIndex$;
  cdkDropListConnectedTo$ = this.userInterfaceService.cdkDropListConnectedTo$;
  getBuilderContainerStyles$ = this.emailObjectStore.builderContainerStyles$;
  currentHTMLTemplate$ = this.emailObjectStore.templateAsObservable$;
  activeMatProgress$ = this.userInterfaceService.activeMatProgress$.asObservable();
  customModuleList$ = this.userRestApiService.getAllUserModules$;

  private readonly _onDestroy$ = new Subject();
  private includedFonts = new Set<HTMLLinkElement>();

  constructor(
    public ngb: IpEmailBuilderService,
    private userRestApiService: IpUserRestApiService,
    private userInterfaceService: IpUserInterfaceService,
    private emailObjectStore: IpEmailObjectStoreService,
    private renderer2: Renderer2,
    private slugifyPipe: SlugifyPipe,
    private middlewares: IpUserMiddlewaresService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(IP_STRUCTURES) public structures: IStructure[],
    @Inject(IP_BLOCKS) public blocks: TBlocks[],
    @Inject(IP_GOOGLE_FONTS) googleFonts: TBlocks[],
    @Inject(DOCUMENT) private doc: Document
  ) {
    if (this.doc) {
      for (const font of googleFonts) {
        const link = this.doc.createElement('link');
        link.href = `https://fonts.googleapis.com/css?family=${font}`;
        link.rel = 'stylesheet';
        this.includedFonts.add(link);
        this.doc.head.appendChild(link);
      }
    }
  }

  // Form Control implementation
  private onChange: (_?: any) => void;
  private onTouched: () => void;

  writeValue(email: IPEmail): void {
    try {
      this.emailObjectStore.setEmail(email);
      this.previewTemplate = false;
      this.changeDetectorRef.markForCheck();
    } catch (error) {
      this.userInterfaceService.notify(error.message);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Not implemented yet
   * @param isDisabled The state of builder
   */
  setDisabledState?(isDisabled: boolean): void {
    // tslint:disable-next-line: no-console
    console.info('[IpEmailBuilderComponent] Method [setDisabledState] is not implemented.');
  }

  saveEmail() {
    return this.emailObjectStore.currentEmailHasChanges$.pipe(
      exhaustMap(hasChanges => {
        return iif(() => hasChanges,
          this.ngb.createHTMLTemplate$(),
          this.userInterfaceService.notify('No changes were detected to be save!').afterDismissed()
        );
      }),
      take(1)
    ).subscribe();
  }

  disableBlocksList$(block: TBlocks) {
    return iif(() => !block.state?.disabled, this.emailObjectStore.emailStructuresAsObservable$.pipe(
      map(({ length }) => length > 0),
      exhaustMap(hasStructures => hasStructures ? this.middlewares.disableBlockDragFromList(block) : deferOf(true)),
    )).pipe(take(1));
  }

  disableStructureList$(structure: IStructure) {
    return this.middlewares.disableStructureDragFromList(structure).pipe(take(1));
  }

  trackByFn(block: TBlocks | IStructure) {
    return block.type;
  }

  changeTabIndex(index: number) {
    this.userInterfaceService.changeTabIndex(index);
  }

  download(source: TExportType) {
    return this.middlewares.exportFile(source).pipe(
      exhaustMap(() => this.ngb.createHTMLTemplate$().pipe(
        map(serverResponse => ({ ...serverResponse, type: source })),
      )),
      catchError((error) => this.middlewares.catchError(error).pipe(
        switchMap(() => {
          this.userInterfaceService.notify(error.message);
          return EMPTY;
        })
      )),
      take(1),
    ).subscribe(({ type, html, mjml }) => {
      const anchor = this.renderer2.createElement('a');
      let download = html;
      if (type === 'mjml') {
        download = mjml;
      } else if (type === 'json') {
        download = JSON.stringify(this.ngb.Email, null, 1);
      }
      const href = URL.createObjectURL(
        new Blob([download], {
          type: type === 'json' ? 'text/json' : 'text/html'
        })
      );
      const fileName = this.slugifyPipe.transform(this.ngb.Email.general.name || 'ngb-template');
      anchor.href = href;
      anchor.target = '_blank';
      anchor.download = `${fileName}.${type}`;
      anchor.click();
      URL.revokeObjectURL(href);
    });
  }

  importFile() {
    this.userInterfaceService.importDialog$().pipe(
      switchMap(emailOrError => {
        if (emailOrError instanceof Error) {
          throw emailOrError;
        }
        return deferOf(emailOrError);
      }),
      catchError((error) => this.middlewares.catchError(error).pipe(
        switchMap(() => {
          this.userInterfaceService.notify(error.message);
          return EMPTY;
        })
      )),
      take(1), filter(email => !!email),
    ).subscribe(email => {
      this.writeValue(email);
      this.userInterfaceService.notify('Email has been imported successfully.');
    });
  }

  // getBackgroundImage(): SafeStyle {
  //   const {
  //     background: { url }
  //   } = this.email.general;
  //   return this.sanitizer.bypassSecurityTrustStyle(url && `url(${url})`);
  // }

  // getBuilderContainerStyles() {
  //   const { background, padding, direction } = this.emailObjectStore.Email.general;

  //   return {
  //     direction,
  //     backgroundRepeat: background.repeat,
  //     backgroundColor: background.color,
  //     backgroundSize: createWidthHeight(background.size),
  //     backgroundPosition: 'top center',
  //     ...createPadding(padding)
  //   };
  // }

  createArrayFromStructureColumns({ columns }): string[] {
    return new Array(columns).fill('');
  }

  togglePreview() {
    return this.emailObjectStore.createHTMLTemplate$().pipe(
      exhaustMap(() => this.middlewares.togglePreview(!this.previewTemplate)),
      catchError(error => this.middlewares.catchError(error).pipe(
        switchMap(() => {
          this.userInterfaceService.notify(error.message);
          return EMPTY;
        })
      )),
      take(1)
    ).subscribe((nextState) => {
      this.previewTemplate = nextState;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnInit() {
    fromEvent<BeforeUnloadEvent>(window, 'beforeunload').pipe(
      withLatestFrom(this.emailObjectStore.currentEmailHasChanges$),
      filter(([_, emailIsDirty]) => emailIsDirty),
      switchMap(([event]) => this.middlewares.preventWindowExit(event)),
      takeUntil(this._onDestroy$)
    ).subscribe(ev => {
      ev.preventDefault();
      ev.returnValue = '';
    });

    this.emailObjectStore.emailAsObservable$.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(email => {
      this.onTouched?.();
      this.onChange?.(email);
      this.emailChange.next(email);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.email) {
      this.writeValue(changes.email.currentValue);
    }
  }

  // private setEmail(email: IPEmail) {
  //   try {
  //     this.emailObjectStore.setEmail(email);
  //     this.previewTemplate = false;
  //     this.changeDetectorRef.markForCheck();
  //   } catch (error) {
  //     this.userInterfaceService.notify(error.message);
  //   }
  // }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    // Reset the builder state
    this.userInterfaceService.reset();

    if (this.doc) {
      this.includedFonts.forEach(font => font.remove());
    }
    this.includedFonts.clear();
  }
}
