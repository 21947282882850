import { Injectable, Inject } from '@angular/core';

import { IP_TEMPLATES_TEMPORARY_STORAGE } from '../tokens';
import { ETemplatesStorage, IUserTemplate, IUserTemplateCategory } from '../interfaces';

@Injectable()
export abstract class IpStorageService {
  constructor(@Inject(IP_TEMPLATES_TEMPORARY_STORAGE) public templateStorage: Storage) { }

  getLatestUsedTemplates(): IUserTemplate[] {
    return JSON.parse(this.templateStorage.getItem(ETemplatesStorage.LATEST_USED)) || [];
  }

  addTemplateToLatestUsed(template: IUserTemplate) {
    const latest = this.getLatestUsedTemplates().filter(({ title }) => title !== template.title);
    latest.unshift(template);
    this.templateStorage.setItem(ETemplatesStorage.LATEST_USED, JSON.stringify(latest));
  }

  cacheTemplateList(list: IUserTemplateCategory[]) {
    this.templateStorage.setItem(ETemplatesStorage.STORAGE, JSON.stringify(list));
  }

  getCachedTemplateList(): IUserTemplateCategory[] {
    return JSON.parse(this.templateStorage.getItem(ETemplatesStorage.STORAGE)) || [];
  }

  clearCachedTemplateList() {
    this.templateStorage.removeItem(ETemplatesStorage.STORAGE);
  }
}
