<div class="dialog-container">
    <h1 mat-dialog-title>Send Campaign</h1>
    <mat-dialog-content fxLayout="column" fxLayoutAlign="space-between">
        <div *ngIf="!(isInitialized$ | async)">
            <mat-spinner diameter="50"></mat-spinner>
        </div>
        <div *ngIf="isInitialized$ | async" fxLayout="column" class="lists-container">
            <h4 class="help-text">Select your target lists and segments from the options below:</h4>
            <div *ngIf="masterList && masterList.segments && masterList.segments.length > 0">
                <span class="list-header">Master List Segments</span>
                <div class="list-container">
                    <div *ngFor="let segment of masterList.segments">
                        <mat-checkbox #segmentCheckbox [value]="segment.id" (change)="segmentSelected($event)">
                            <span class="segment-name">{{segment.name}} - ({{segment.count}})</span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="customLists && customLists.length > 0">
                <span class="list-header">Custom Lists</span>
                <div class="list-container">
                    <div *ngFor="let list of customLists">
                    <mat-checkbox #listCheckbox id="" [value]="list.id" (change)="listSelected($event)">
                        <span class="list-name">{{list.name}} - ({{list.count}})</span>
                    </mat-checkbox> 
                    <div *ngIf="list.segments && list.segments.length > 0" class="segment-container">
                        <div *ngFor="let segment of list.segments">
                        <mat-checkbox [disabled]="checkedLists.includes(list.id)" #segmentCheckbox [value]="segment.id" (change)="segmentSelected($event)">
                            <span class="segment-name">{{segment.name}} - ({{segment.count}})</span>
                        </mat-checkbox>
                        </div>
                    </div>
                    </div>
                </div>
                <hr />
            </div>
            <div>
                <p>To schedule your campaign select a date and time below. Leaving this blank will send your campaign immediately.</p>
                <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Select a date and time" 
                      [min]="minDate" [disabled]="disabled" [(ngModel)]='datePicker'>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSeconds]="showSeconds" [enableMeridian]="enableMeridian">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
                
            </div>
            <div>
                <span><mat-icon role="img" color="primary" class="mat-icon notranslate mat-accent material-icons" aria-hidden="true" ng-reflect-color="accent">group</mat-icon> Recipients: {{recipients}}</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <button mat-button (click)="send()">Send</button>
    </mat-dialog-actions>
</div>