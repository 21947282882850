import { TBlocks } from './classes/Elements';

export interface IForRootConf {
  xApiKey?: string;

  /**
   * @deprecated Rewrite IpUserRestApiService in providers instead
   */
  uploadImagePath?: string;
  /**
  * @deprecated Rewrite IpUserRestApiService in providers instead
  */
  uploadImageName?: string;
  /**
  * @deprecated Rewrite IpUserRestApiService in providers instead
  */
  csrf?: { name: string; token: string };
  /**
  * @deprecated Rewrite IpUserRestApiService in providers instead
  */
  apiPath?: string;

  useDownloadButton?: boolean;
  usePreviewButton?: boolean;
  useSaveButton?: boolean;
  useImportButton?: boolean;
  templateListIfEmpty?: boolean;
}

// export interface ITestEmail {
//   to: string;
//   from?: string;
//   subject?: string;
// }

export interface IMjmlServerResponse {
  html: string;
  mjml: string;
  errors: { message: string, tagName: string }[];
}

export type TStructreTypes =
  | 'cols_1'
  | 'cols_2'
  | 'cols_3'
  | 'cols_4'
  | 'cols_12'
  | 'cols_21';
export type TDirection = 'ltr' | 'rtl';
export type TUnits = '%' | 'px' | 'cover' | 'contain';
export type TAlign = 'left' | 'center' | 'right';
export type TVerticalAlign = 'top' | 'middle' | 'bottom';
export type TLineHeight = '%' | 'px' | 'none';
export type TLinkTarget = '_blank' | '_self' | '_parent' | '_top';
export type TFontStyle = 'italic' | 'normal' | 'oblique';
export type TFontWeight =
  | number
  | 'bold'
  | 'bolder'
  | 'inherit'
  | 'initial'
  | 'light'
  | 'normal';
export type TBackgroundRepeat =
  | 'no-repeat'
  | 'repeat'
  | 'repeat-x'
  | 'repeat-y';

export interface IBorder {
  color?: string;
  style?: 'solid' | 'dotted' | 'dashed' | 'double' | 'groove';
  width?: number;
  radius?: number;
}

export interface IPadding {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export interface IMargin {
  top?: number;
  bottom?: number;
}

export interface IWidthHeight {
  value: number;
  unit: TUnits;
  auto?: boolean;
  units?: TUnits[];
}

export interface IBackground {
  color?: string;
  url?: string;
  repeat?: TBackgroundRepeat;
  size?: IWidthHeight;
}

export interface IFont {
  family?: string;
  fallback?: string;
  size?: number;
  style?: TFontStyle;
  weight?: TFontWeight;
}

export type IFontFamily = Set<string>;

export interface ILineHeight {
  value?: number;
  unit?: TLineHeight;
}

export interface ILink {
  href: string;
  target: TLinkTarget;
}

export interface IStructureColumnOptions {
  background?: IBackground;
  border?: IBorder;
  verticalAlign?: TVerticalAlign;
}

export interface IStructureOptions {
  border?: IBorder;
  background?: IBackground;
  padding?: IPadding;
  margin?: IMargin;
  disableResponsive?: boolean;
  gaps?: [number, number];
  columnsWidth?: number[];
  columns?: IStructureColumnOptions[];
}

export interface IStructure {
  readonly type: TStructreTypes;
  // unique IDs for unique class attribute
  readonly id: number;
  isModule?: boolean;
  options: IStructureOptions;
  elements: TBlocks[][];
  readonly columns: number;
}

export interface IBlockState {
  disabled: boolean;
  message: string;
}

export interface ITextBlockOptions {
  color?: string;
  font?: IFont;
  lineHeight?: ILineHeight;
  padding?: IPadding;
}

export interface IImageBLockOptions {
  border?: IBorder;
  width?: IWidthHeight;
  height?: IWidthHeight;
  link?: ILink;
  align?: TAlign;
  title?: string;
  padding?: IPadding;
}

export interface IButtonBlockOptions {
  backgroundColor?: string;
  border?: IBorder;
  color?: string;
  font?: IFont;
  align?: TAlign;
  fullWidth?: boolean;
  lineHeight?: ILineHeight;
  link?: ILink;
  innerPadding?: IPadding;
  padding?: IPadding;
}

export interface IDividerBlockOptions {
  border?: IBorder;
  padding?: IPadding;
}

export interface ISpacerBlockOptions {
  height?: IWidthHeight;
  width?: IWidthHeight;
}

export interface ISocialBlockOptions {
  align?: TAlign;
  mode?: 'vertical' | 'horizontal';
  font?: IFont;
  iconSize?: ILineHeight;
  lineHeight?: ILineHeight;
  color?: string;
  innerPadding?: IPadding;
  padding?: IPadding;
}

export interface ISocialNetwork {
  href: string;
  // target?: string;
  label?: string;
  name:
  | 'github'
  | 'instagram'
  | 'web'
  | 'snapchat'
  | 'youtube'
  | 'vimeo'
  | 'medium'
  | 'soundcloud'
  | 'dribbble'
  | 'facebook'
  | 'twitter'
  | 'pinterest'
  | 'linkedin'
  | 'tumblr'
  | 'xing';
  padding?: IPadding;
}

export interface IGeneralOptions {
  width?: IWidthHeight;
  background?: IBackground;
  padding?: IPadding;
  direction?: TDirection;
  name?: string;
  previewText: string;
  global?: {
    fonts?: string[];
    padding?: IPadding;
  };
}

export interface IIPEmail {
  general?: IGeneralOptions;
  structures?: IStructure[];
}

export interface IUserModule {
  /**
  * @deprecated Add name instead
  */
  thumb?: string;
  name?: string;
  module: IStructure;
}

/**
* @deprecated use IUserModule instead
*/
// tslint:disable-next-line: no-empty-interface
export interface ICustomModule extends IUserModule { }

export interface IUserTemplate {
  id?: string;
  title: string;
  thumbPath: string;
  templateData: IIPEmail;
}

/**
* @deprecated use IUserTemplate instead
*/
// tslint:disable-next-line: no-empty-interface
export interface ICustomTemplate extends IUserTemplate { }

export interface IUserTemplateCategory {
  category: string;
  templates: IUserTemplate[];
}

export interface IUserImageCategory {
  category: string;
  images: string[];
}


export enum ETemplatesStorage {
  LATEST_USED = 'NGB_LATEST_USED_TEMPLATES',
  STORAGE = 'NGB_TEMP_TEMPLATES_STORAGE'
}