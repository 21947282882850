import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-gaps',
  template: `
    <div class="group">
      <mat-form-field appearance="outline">
        <mat-label i18n="Gaps settings|Vertical Gaps">Vertical Gaps</mat-label>
        <input matInput type="number" min="0" step="1" [(ngModel)]="gaps[0]" (input)="markForCheck()" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Gaps settings|Horizontal Gaps">Horizontal Gaps</mat-label>
        <input matInput type="number" min="0" step="1" [(ngModel)]="gaps[1]" (input)="markForCheck()" />
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GapsComponent {
  @Input() gaps: [number, number];
  constructor(private emailObject: IpEmailObjectStoreService) { }

  markForCheck() {
    this.emailObject.markForCheck();
  }
}
