<ng-container *ngIf="getStructures$ | async as structures">
  <div [fxFlex]="getEmailBodyWidth$ | async" cdkDropList [cdkDropListData]="structures"
    cdkDropListConnectedTo="structures-drop-list" id="structures-drop-list"
    (cdkDropListDropped)="dropNewStructure($event)">
    <ip-structure cdkDrag *ngFor="let structure of structures; trackBy: trackBy; let index = index" [id]="structure.id"
      [index]="index" [ngClass]="structure.type" [structure]="structure" [ngStyle]="getStructureStyles(structure)"
      [style.backgroundImage]="getBackgroundImage(structure)"
      [cdkDragDisabled]="disableStructureDrag$(structure) | async">
      <button cdkDragHandle mat-icon-button class="move" *ngIf="!(disableStructureDrag$(structure) | async)"
        i18n-matTooltip="structure|Move Structure" matTooltip="Move Structure">
        <mat-icon i18n-aria-label="structure|Move Structure" aria-label="Move structure" inline>pan_tool</mat-icon>
      </button>
    </ip-structure>

    <div class="empty-email mat-typography" *ngIf="!structures.length">
      <ng-content></ng-content>
      <div class="default-empty-email" *ngIf="showTemplateList">
        <h1 i18n="templates|First header">Choose template from list</h1>
        <p i18n="templates|Description">Or create one from scratch by dragging structures and blocks here!</p>
        <button type="button" color="primary" [disabled]="activeMatProgress$ | async"
          (click)="openTemplateDialog($event)" mat-flat-button i18n="templates|Choose a template button">
          Choose template
        </button>
      </div>
    </div>
  </div>
</ng-container>