import { Injectable, Inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { pluck, map, exhaustMap, switchMap, withLatestFrom } from 'rxjs/operators';
// import { getDiff } from 'recursive-diff';

import { IAddStructureEvent, IAddBlockEvent, IpUserMiddlewaresService } from './user-middleware-service/ip-middlewares.service';
import { IStructure, IMjmlServerResponse, IUserTemplate } from './interfaces';
import { TBlocks } from './classes/Elements';
import { IPEmail } from './classes/DefaultEmail';
import { cloneDeep, isEqual, createWidthHeight, createPadding, deferOf } from './utils';
import { IpUserRestApiService } from './user-rest-api-service/user-rest-api.service';
import { IpStorageService } from './ip-storage/ip-storage.service';
import { IP_GOOGLE_FONTS } from './tokens';

// https://www.npmjs.com/package/recursive-diff

@Injectable({
  providedIn: 'root'
})
export class IpEmailObjectStoreService {

  public Email = new IPEmail();
  private clonedEmail = cloneDeep<IPEmail>(this.Email);
  private _Email$ = new BehaviorSubject<IPEmail>(this.Email);
  private _Mjml$ = new BehaviorSubject<string>('');
  private _Template$ = new BehaviorSubject<string>('');
  private _onTemplateCreated$ = new Subject<any>();

  public readonly emailAsObservable$ = this._Email$.asObservable();
  public readonly mjmlAsObservable$ = this._Mjml$.asObservable();
  public readonly templateAsObservable$ = this._Template$.asObservable();
  public readonly onTemplateCreated$ = this._onTemplateCreated$.pipe(
    withLatestFrom(this._Email$, this._Template$, this._Mjml$),
    map(([_, ...rest]) => rest)
  );

  public readonly currentEmailHasChanges$ = this.emailAsObservable$.pipe(
    map((current) => !isEqual(current, this.clonedEmail)),
    // shareReplay(),
    // tap((isDirty) => console.log('IsDirty', isDirty)),
  );

  public readonly emailStructuresAsObservable$ = this.emailAsObservable$.pipe(
    pluck('structures')
  );

  public readonly generalEmailOptionsAsObservable$ = this.emailAsObservable$.pipe(
    pluck('general')
  );

  public readonly builderContainerStyles$ = this.generalEmailOptionsAsObservable$.pipe(
    map(({ background, padding, direction }) => ({
      direction,
      backgroundRepeat: background.repeat,
      backgroundColor: background.color,
      backgroundSize: createWidthHeight(background.size),
      backgroundPosition: 'top center',
      ...createPadding(padding)
    }))
  );

  public readonly emailBodyWidth$ = this.generalEmailOptionsAsObservable$.pipe(
    map(({ width }) => `1 1 ${createWidthHeight(width)}`)
  );

  constructor(
    private userRestApi: IpUserRestApiService,
    private ipStorage: IpStorageService,
    private userMiddleware: IpUserMiddlewaresService,
    @Inject(IP_GOOGLE_FONTS) private googleFonts: string[]
  ) { }

  createHTMLTemplateRaw(email: IPEmail) : Promise<IMjmlServerResponse> {
    return this.userMiddleware.createHTMLTemplate(email).pipe(
      exhaustMap(email => this.userRestApi.createHTMLTemplate$({ ...email, googleFonts: this.googleFonts })),
      map(res => {
        if (res.errors.length) {
          const error = res.errors.map(({ message, tagName }) => `${tagName} > ${message}`).join('\n');
          throw new Error(error);
        }

        return res;
      })
    ).toPromise();
  }

  createHTMLTemplate$() {
    return this.emailAsObservable$.pipe(
      switchMap(email => {
        if (!email.structures.length) {
          throw new Error('Please add some structures and blocks to perform this action.');
        }
        return deferOf(email);
      }),
      withLatestFrom(combineLatest([this.currentEmailHasChanges$, this.templateAsObservable$])),
      exhaustMap(([email, [hasChanges, currentHtml]]) => {
        if (hasChanges || !currentHtml) {
          return this.userMiddleware.createHTMLTemplate(email).pipe(
            exhaustMap(email => this.userRestApi.createHTMLTemplate$({ ...email, googleFonts: this.googleFonts })),
            map(res => {
              if (res.errors.length) {
                const error = res.errors.map(({ message, tagName }) => `${tagName} > ${message}`).join('\n');
                throw new Error(error);
              }
              this._Mjml$.next(res.mjml);
              this._Template$.next(res.html);

              this.clonedEmail = cloneDeep(email);
              this._Email$.next(email);
              this._onTemplateCreated$.next();
              return res;
            })
          );
        }
        return combineLatest([this._Mjml$, this._Template$]).pipe(
          map(([mjml, html]) => ({ html, mjml, errors: [] }) as IMjmlServerResponse)
        );
      })
    );
  }

  markForCheck() {
    this._Email$.next(this.Email);

    if (this.Email.structures.length) {
      this.ipStorage.addTemplateToLatestUsed(
        cloneDeep<IUserTemplate>({
          templateData: this.Email, title: 'last-edited', thumbPath: 'https://via.placeholder.com/200x300?text=LAST+EDITED'
        })
      );
    }
    // console.log(getDiff(this.clonedEmail, this.Email));
  }

  setEmail(newEmail: IPEmail) {
    if (!newEmail.structures || !newEmail.general) {
      throw new Error('Injected object is not a valid IPEmail');
    }
    this.Email = newEmail;
    this.reset();
  }

  addStructure({ currentIndex = 0, item }: IAddStructureEvent): IStructure {
    console.log(item);
    const { structures = [] } = this.Email;
    let isModule = true;
    if (!item.data.elements.length) {
      isModule = false;
      item.data.elements = Array.from({ length: item.data.columns }, () => []);
    }
    const newStructure = { id: Date.now(), ...cloneDeep<IStructure>(item.data), isModule };
    structures.splice(currentIndex, 0, newStructure);
    this.markForCheck();
    return newStructure;
  }

  changeStructureOrder({ previousIndex = 0, currentIndex = 0 }: CdkDragDrop<Set<IStructure>>) {
    moveItemInArray(this.Email.structures, previousIndex, currentIndex);
    this.markForCheck();
  }

  duplicateStructure(index: number): IStructure {
    const { structures = [] } = this.Email;
    const newStructure = cloneDeep<IStructure>({ ...structures[index], id: Date.now() });
    structures.splice(index, 0, newStructure);
    this.markForCheck();
    return newStructure;
  }

  removeStructure(index: number) {
    const { structures = [] } = this.Email;
    structures.splice(index, 1);
    this.markForCheck();
  }

  addBlock({ previousIndex = 0, currentIndex = 0, item, previousContainer: { id, data } }: IAddBlockEvent, column: TBlocks[]) {

    // console.log(id);
    // console.log(item);

    if (id === 'block-elements') {
      column.splice(currentIndex, 0, cloneDeep(item.data));
    } else {
      transferArrayItem(data, column, previousIndex, currentIndex);
    }
    this.markForCheck();
  }

  changeBlockOrder({ previousIndex = 0, currentIndex = 0 }: CdkDragDrop<TBlocks[]>, column: TBlocks[]) {
    moveItemInArray(column, previousIndex, currentIndex);
    this.markForCheck();
  }

  duplicateBlock(key: number, column: TBlocks[], block: TBlocks): TBlocks {
    const newBlock = cloneDeep<TBlocks>(block);
    column.splice(key, 0, newBlock);
    this.markForCheck();
    return newBlock;
  }

  removeBlock(key: number, column: TBlocks[]) {
    column.splice(key, 1);
    this.markForCheck();
  }

  reset() {
    this._Mjml$.next(null);
    this._Template$.next(null);
    this.clonedEmail = cloneDeep(this.Email);
    this.markForCheck();
  }
}
