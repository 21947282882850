<ip-email-builder [email]="currentEmail$ | async">
  <!-- Include custom html near top buttons -->
  <div fxLayout="row" fxFlex="fill" class="top-actions">
    <button *ngIf="!(isEditing$ | async)" mat-raised-button color="primary" (click)="save()">Save {{ (emailItem$ | async)?.itemType | titlecase}}</button>
    <button class="send-button" *ngIf="(emailItem$ | async)?.itemType === 'campaign' && (isEditing$ | async)" mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      <span class="send-button-title">Send</span>
      <mat-icon class="send-button-icon">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="testCampaign()" mat-menu-item>
        <mat-icon color="accent">science</mat-icon>
        <span>Send Test Email</span>
      </button>
      <button [disabled]="(emailItem$ | async)?.isSent" (click)="sendCampaign()" mat-menu-item>
      <mat-icon  color="accent">send</mat-icon>
        <span >Send Campaign</span>
      </button>
    </mat-menu>
    <span class="last-saved-text" *ngIf="(isEditing$ | async) && lastSavedDate">Last Saved: {{lastSavedDate}}</span>
    <span class="last-saved-text" *ngIf="(isEditing$ | async) && (emailItem$ | async)?.sendDate">Campaign sent: {{(emailItem$ | async)?.sendDate.toLocaleString('en-US', {timeZone: 'America/New_York'})}}</span>
    <div fxFlex></div>
    <button *ngIf="(isEditing$ | async)" mat-stroked-button color="primary" (click)="save()">{{ (emailItem$ | async)?.itemType | titlecase}} Settings</button>
  </div>
</ip-email-builder>
