<div class="dialog-container">
  <h1 mat-dialog-title>Save {{formData.type | titlecase}}</h1>
  <div mat-dialog-content>
    <div fxLayout="column">
      <div *ngIf="data.itemType === 'campaign'" fxLayout="row">
        <mat-form-field>
          <input matInput placeholder="Email Subject" [(ngModel)]="formData.title">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="From Name" [(ngModel)]="formData.fromName">
        </mat-form-field>
      </div>
      <div *ngIf="data.itemType === 'campaign'" fxLayout="row">
        <mat-form-field>
          <input matInput placeholder="From Email" [(ngModel)]="formData.fromEmail" disabled="true">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Reply To" [(ngModel)]="formData.replyTo">
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="data.itemType === 'template'" fxLayout="row">
      <mat-form-field>
        <input matInput placeholder="Template Title" [(ngModel)]="formData.title">
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button mat-button (click)="save()" cdkFocusInitial>Save</button>
  </div>
</div>
