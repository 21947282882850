import {
  IFont,
  IPadding,
  IBorder,
  ILineHeight,
  IWidthHeight,
  IBackground,
  IMargin
} from './interfaces';
import { defer, of } from 'rxjs';
// import { getDiff } from 'recursive-diff';

// import { isIvy } from './ivy-detections';

export function cloneDeep<T>(obj = {}): T {
  return JSON.parse(JSON.stringify(obj));
}

export function isEqual(x: any, y: any) {
  // return !getDiff(x, y).length;
  return JSON.stringify(x) === JSON.stringify(y);
}

export function defaultsDeep<T>(to: T = {} as T, ...sources: T[]) {
  for (const source of sources) {
    for (const key in source) {
      if (to[key]) continue
      if (!Array.isArray(source[key]) && typeof source[key] === 'object') {
        to[key] = defaultsDeep(to[key], source[key])
      } else {
        to[key] = source[key];
      }
    }
  }
  return to as unknown as T
}

export const createBorder = (
  { color = '#000000', style = 'solid', width = 4, radius = 0 }: IBorder,
  rule = 'border'
) => {
  return {
    [rule]: `${width}px ${style} ${color}`,
    borderRadius: `${radius}px`
  };
};

export const createPadding = (
  { top = 10, right = 25, bottom = 10, left = 25 }: IPadding,
  rule = 'padding'
) => {
  return {
    [rule]: `${top}px ${right}px ${bottom}px ${left}px`
  };
};

export const createMargin = ({ top = 0, bottom = 0 }: IMargin) => {
  return {
    margin: `${top}px 0 ${bottom}px`
  };
};

export const createFont = ({
  family = '',
  size = 13,
  style = 'normal',
  weight = 'normal'
}: IFont) => {
  return {
    fontFamily: family,
    fontSize: `${size}px`,
    fontStyle: style,
    fontWeight: weight
  };
};

export const createLineHeight = ({ value = 22, unit = 'px' }: ILineHeight) => {
  return {
    lineHeight: unit !== 'none' ? `${value}${unit}` : 'normal'
  };
};

export const createBackground = ({
  url = '',
  color = 'white',
  repeat = 'no-repeat'
}: IBackground): string => {
  return `${color} ${url && `url(${url})`} ${repeat} top center`;
};

export const createWidthHeight = ({
  value = 100,
  unit = '%',
  auto = false
}: IWidthHeight) => {
  return (
    (auto && 'auto') ||
    (['%', 'px'].indexOf(unit) > -1 && `${value}${unit}`) ||
    unit
  );
};

export function bytesToSize(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export function getAssetByPath(path: string): string | JSON {
  return `https://www.mailjet.com/images/theme/v1/icons/ico-social/${path}`;
}


export function deferOf<T>(...args: T[]) {
  return defer(() => of(...args))
}