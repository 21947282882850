import { NgModule, ModuleWithProviders, Inject } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBottomSheetModule, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResizableModule } from 'angular-resizable-element';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';

import 'quill';
import { QuillModule, QUILL_CONFIG_TOKEN } from 'ngx-quill';
import { ColorChromeModule } from 'ngx-color/chrome';

import { IForRootConf } from './interfaces';
import { IP_CONFIG, IP_TEMPLATES_TEMPORARY_STORAGE } from './tokens';
import { StructureComponent } from './components/structure/structure.component';
import { IpEmailBuilderComponent } from './ip-email-builder.component';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';

import { ImageComponent } from './elements/image/image.component';
import { TextElementComponent } from './elements/text-element/text-element.component';
import { ButtonComponent } from './elements/button/button.component';
import { DividerComponent } from './elements/divider/divider.component';
import { SpacerComponent } from './elements/spacer/spacer.component';
import { SocialComponent } from './elements/social/social.component';

import { BuilderContainerComponent } from './builder-container/builder-container.component';
import { FontStylesComponent } from './groups/font-styles';
import { PaddingComponent } from './groups/padding';
import { LineHeightComponent } from './groups/line-height';
import { WidthHeightComponent } from './groups/width-height';
import { BorderComponent } from './groups/border';
import { ColorComponent } from './groups/color';
import { LinkComponent } from './groups/link';
import { AlignComponent } from './groups/align';
import { DirectionComponent } from './groups/direction';
import { BackRepatComponent } from './groups/back-repeat';
import { ConfirmDialogComponent } from './components/dialog.component';
import { PreviewTemplateComponent } from './components/preview.component';
import { IpEmailBuilderService } from './ip-email-builder.service';
import { BlockComponent } from './components/block/block.component';
import { ImageUploadComponent } from './groups/upload-image';
import { BlockSettingsComponent } from './components/block-settings/block-settings.component';
import { StructureSettingsComponent } from './components/structure-settings/structure-settings.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { MarginComponent } from './groups/margin';
import { TemplateListDialogComponent } from './components/template-list-dialog/template-list-dialog.component';
import { GapsComponent } from './groups/gaps';

import { SlugifyPipe } from './slugify.pipe';

import { FreeUsersMiddleware, PaidUsersMiddleware } from './user-middleware-service';
import { FreeUsersImageUploader, PaidUsersImageUploader } from './user-image-uploader-service';

import { IpUserMiddlewaresService } from './user-middleware-service/ip-middlewares.service';
import { IpUserImageUploaderService } from './user-image-uploader-service/user-image-uploader.service';

import { IpInterceptorInterceptor } from './ip-interceptor.interceptor';
import { ImportDialogComponent } from './components/import-dialog/import-dialog.component';
import { UploadBottomSheetDialogComponent } from './user-image-uploader-service/upload-bottom-sheet-dialog/upload-bottom-sheet-dialog.component';
import { UploadImageGalleryComponent } from './user-image-uploader-service/upload-image-gallery/upload-image-gallery.component';

import { LIBRARY_IS_PRO } from './private-tokens';
import { IpUserInterfaceService } from './user-interface.service';
import { IpEmailObjectStoreService } from './ip-email-object-store.service';
import { FreeUsersRestApi } from './user-rest-api-service';
import { FreeUsersIpStorage } from './ip-storage';
import { IpStorageService } from './ip-storage/ip-storage.service';
import { IpUserRestApiService } from './user-rest-api-service/user-rest-api.service';

export function createCustomRestApiService(http: HttpClient) {
  return new FreeUsersRestApi(http);
}

export function createUserMiddlewareService(libraryIsProEnv: boolean) {
  if (!libraryIsProEnv) {
    return new FreeUsersMiddleware();
  } else {
    return new PaidUsersMiddleware();
  }
}

export function createUsersIpStorage(templateStorage: Storage) {
  return new FreeUsersIpStorage(templateStorage);
}

export function createUserUploaderService(
  libraryIsProEnv: boolean,
  matBottomSheet: MatBottomSheet,
  matDialog: MatDialog,
  userMiddleware: IpUserMiddlewaresService,
  userRestApi: IpUserRestApiService
) {
  if (!libraryIsProEnv) {
    return new FreeUsersImageUploader(matBottomSheet, userMiddleware);
  } else {
    return new PaidUsersImageUploader(matDialog, userMiddleware, userRestApi);
  }
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    QuillModule,
    FlexLayoutModule,
    DragDropModule,
    FormsModule,
    ColorChromeModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatExpansionModule,
    ResizableModule,
    ExperimentalScrollingModule,
    ScrollingModule,
  ],
  declarations: [
    IpEmailBuilderComponent,
    StructureComponent,
    BlockComponent,
    DynamicComponentDirective,
    PreviewTemplateComponent,

    TextElementComponent,
    ImageComponent,
    ButtonComponent,
    DividerComponent,
    SpacerComponent,
    SocialComponent,

    BuilderContainerComponent,
    FontStylesComponent,
    PaddingComponent,
    MarginComponent,
    GapsComponent,
    LineHeightComponent,
    WidthHeightComponent,
    BorderComponent,
    ColorComponent,
    LinkComponent,
    AlignComponent,
    DirectionComponent,
    BackRepatComponent,
    ConfirmDialogComponent,
    ImageUploadComponent,
    BlockSettingsComponent,
    StructureSettingsComponent,
    GeneralSettingsComponent,
    UploadBottomSheetDialogComponent,
    UploadImageGalleryComponent,
    TemplateListDialogComponent,
    SlugifyPipe,
    ImportDialogComponent
  ],
  exports: [IpEmailBuilderComponent, PreviewTemplateComponent],
  entryComponents: [
    TextElementComponent,
    ImageComponent,
    ButtonComponent,
    DividerComponent,
    SpacerComponent,
    SocialComponent,
    ConfirmDialogComponent,
    UploadBottomSheetDialogComponent,
    UploadImageGalleryComponent,
    TemplateListDialogComponent,
    ImportDialogComponent
  ],
  providers: [
    {
      provide: QUILL_CONFIG_TOKEN, useValue: {
        bounds: 'ip-builder-container',
        theme: 'bubble',
        format: 'html',
        trackChanges: 'all'
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: IpInterceptorInterceptor, multi: true },
    IpEmailBuilderService,
    IpEmailObjectStoreService,
    IpUserInterfaceService,
  ]
})
export class IpEmailBuilderModule {

  constructor(
    ipMiddlewaresService: IpUserMiddlewaresService,
    userImageUploader: IpUserImageUploaderService,
    @Inject(LIBRARY_IS_PRO) libraryIsPro: boolean,
  ) {
    if (!libraryIsPro && !(ipMiddlewaresService instanceof FreeUsersMiddleware)) {
      throw new Error('Upgrade to an Extended or Commercial License to use Custom Middlewares.');
    }
    if (!libraryIsPro && !(userImageUploader instanceof FreeUsersImageUploader)) {
      throw new Error('Upgrade to an Extended or Commercial License to use Custom Image Uploader.');
    }
  }

  /**
   * @deprecated use IpEmailBuilderModule.withConfig(userConfig) instead
   */
  static forRoot(userConfig: IForRootConf = {}): ModuleWithProviders<IpEmailBuilderModule> {
    return IpEmailBuilderModule.withConfig(userConfig);
  }

  /**
   * Import module with configurations
   * @param userConfig Configurations
   */
  static withConfig(userConfig: IForRootConf = {}): ModuleWithProviders<IpEmailBuilderModule> {
    return {
      ngModule: IpEmailBuilderModule,
      providers: [
        IpEmailBuilderService,
        IpEmailObjectStoreService,
        IpUserInterfaceService,
        { provide: IP_CONFIG, useValue: userConfig },
        {
          provide: IpUserMiddlewaresService, useFactory: createUserMiddlewareService,
          deps: [LIBRARY_IS_PRO]
        },
        {
          provide: IpStorageService, useFactory: createUsersIpStorage,
          deps: [IP_TEMPLATES_TEMPORARY_STORAGE]
        },
        {
          provide: IpUserImageUploaderService,
          useFactory: createUserUploaderService,
          deps: [LIBRARY_IS_PRO, MatBottomSheet, MatDialog, IpUserMiddlewaresService, IpUserRestApiService]
        },
        {
          provide: IpUserRestApiService,
          useFactory: createCustomRestApiService,
          deps: [HttpClient]
        }
      ]
    };
  }
}
