import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import { IpEmailObjectStoreService } from '../../ip-email-object-store.service';

@Component({
  selector: 'ip-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GeneralSettingsComponent {
  generalOptions$ = this.ipEmailObject.generalEmailOptionsAsObservable$;

  constructor(private ipEmailObject: IpEmailObjectStoreService) { }
}
