import {
  Component,
  HostListener,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { switchMap, take, exhaustMap, catchError, finalize, map, tap } from 'rxjs/operators';
import { EMPTY, throwError, iif } from 'rxjs';

import { createWidthHeight, createBorder, createPadding, createMargin, deferOf } from '../utils';
import { IStructure, IForRootConf, IUserTemplate } from '../interfaces';
import { IPEmail } from '../classes/DefaultEmail';
import { IpUserMiddlewaresService, IAddStructureEvent } from '../user-middleware-service/ip-middlewares.service';
import { IpUserInterfaceService } from '../user-interface.service';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';
import { IpUserRestApiService } from '../user-rest-api-service/user-rest-api.service';
import { IP_CONFIG } from '../tokens';
import { IpStorageService } from '../ip-storage/ip-storage.service';
// import { IpUserRestApiProvider } from '../providers';

@Component({
  selector: 'ip-builder-container',
  templateUrl: './builder-container.component.html',
  styleUrls: ['./builder-container.scss'],
  providers: [
    // IpUserRestApiProvider
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
  // encapsulation: ViewEncapsulation.None
})
export class BuilderContainerComponent {
  showTemplateList = this.userConfig.templateListIfEmpty ?? true;
  getStructures$ = this.emailObjectStore.emailStructuresAsObservable$;
  getEmailBodyWidth$ = this.emailObjectStore.emailBodyWidth$;
  activeMatProgress$ = this.userInterfaceService.activeMatProgress$;

  @HostListener('click') onHostClick() {
    this.userInterfaceService.editGeneralSettings();
  }

  constructor(
    private userInterfaceService: IpUserInterfaceService,
    private emailObjectStore: IpEmailObjectStoreService,
    private userRestApi: IpUserRestApiService,
    private ipStorage: IpStorageService,
    private ipMiddlewaresService: IpUserMiddlewaresService,
    private sanitizer: DomSanitizer,
    @Inject(IP_CONFIG) private userConfig: IForRootConf
  ) { }

  trackBy(struncture: IStructure): number {
    return struncture.id;
  }

  disableStructureDrag$(structure: IStructure) {
    return this.ipMiddlewaresService.disableStructureDragWithinEmailBody(structure).pipe(take(1));
  }

  openTemplateDialog(ev: Event) {
    ev.stopImmediatePropagation();
    this.userInterfaceService.activeMatProgress$.next(true)
    const cachedTemplates = this.ipStorage.getCachedTemplateList()
    return iif(() => cachedTemplates.length > 0,
      deferOf(cachedTemplates), this.userRestApi.getAllUserTemplates$.pipe(
        tap(list => this.ipStorage.cacheTemplateList(list))
      )
    ).pipe(
      // map(() => []),
      map(templates => {
        return [...templates, { category: 'latest', templates: this.ipStorage.getLatestUsedTemplates() }];
      }),
      finalize(() => this.userInterfaceService.activeMatProgress$.next(false)),
      exhaustMap(list => list?.length > 0 ? this.userInterfaceService.templatesListDialog$(list) : throwError(new Error('The template list is empty'))),
      switchMap(({ category, template }) => this.ipMiddlewaresService.chooseTemplate(category, template)),
      exhaustMap(({ category, template }) => this.userRestApi.getUserTemplateData$(category, template).pipe(
        tap(choosedTemplate => {
          if (category !== 'latest') {
            this.ipStorage.addTemplateToLatestUsed(choosedTemplate)
          }
        })
      )),
      catchError((error: Error | HttpErrorResponse) => this.ipMiddlewaresService.catchError(error).pipe(
        switchMap(() => {
          this.userInterfaceService.notify(error.message);
          return EMPTY;
        })
      )),
      take(1)
    ).subscribe((result: IUserTemplate) => {
      if (result) {
        this.emailObjectStore.setEmail(new IPEmail(result.templateData));
      } else {
        this.userInterfaceService.notify('No template was chosen');
      }
    });
  }

  dropNewStructure(event: IAddStructureEvent) {
    if (event.previousContainer === event.container) {
      this.emailObjectStore.changeStructureOrder(event);
    } else {
      this.ipMiddlewaresService.addStructure(event).pipe(
        tap(newEvent => this.emailObjectStore.addStructure(newEvent)),
        take(1)
      ).subscribe();
    }
  }

  getStructureStyles(structure: IStructure) {
    const { border, background, padding, margin, columnsWidth } = structure.options;

    return {
      // direction,
      backgroundRepeat: background.repeat,
      backgroundColor: background.color,
      backgroundSize: createWidthHeight(background.size),
      backgroundPosition: 'top center',
      gridTemplateColumns: columnsWidth.map(fr => `${fr}fr`).join(' '),
      ...createBorder(border),
      ...createPadding(padding),
      ...createMargin(margin)
    };
  }

  getBackgroundImage(structure: IStructure): SafeStyle {
    const {
      background: { url }
    } = structure.options;
    return this.sanitizer.bypassSecurityTrustStyle(url && `url(${url})`);
  }
}
