import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ILink, TLinkTarget } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-link',
  template: `
    <div class="group f-large">
      <mat-form-field appearance="outline">
        <mat-label i18n="Link Settings|Link">Link</mat-label>
        <input matInput [(ngModel)]="link.href" type="url" placeholder="Link" (blur)="markForCheck()" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Link Settings|Target">Target</mat-label>
        <mat-select placeholder="Target" [(value)]="link.target" disableRipple (selectionChange)="markForCheck()">
          <mat-option *ngFor="let target of getTargets()" [value]="target" i18n>
            {{ getTargetLabel(target) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
  @Input() link: ILink;

  private targetLabels = new Map([
    ['_blank', 'Blank'],
    ['_self', 'Self'],
    ['_parent', 'Parent'],
    ['_top', 'Top']
  ]);

  constructor(private emailObject: IpEmailObjectStoreService) { }

  markForCheck() {
    this.emailObject.markForCheck();
  }

  getTargets(): TLinkTarget[] {
    return ['_blank', '_self', '_parent', '_top'];
  }

  getTargetLabel(target: string) {
    return this.targetLabels.get(target);
  }
}
