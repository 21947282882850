<div class="column" *ngFor="let column of structure.elements; let index = index" cdkDropList mwlResizable
  [enableGhostResize]="false" [resizeSnapGrid]="{ left: fr, right: fr }" [resizeEdges]="getResizeEdges(index)"
  [validateResize]="validate" (resizing)="onResizeEnd($event, index)"
  (cdkDropListDropped)="dropNewBlock($event, column)" [class.empty]="!column.length" [ngStyle]="getColumnStyles(index)"
  [cdkDropListConnectedTo]="cdkDropListConnectedTo$ | async" [cdkDropListData]="column" [id]="createColumnId(index)">

  <div *ngIf="!column.length" class="empty-block" i18n="structure|Inside empty block">DnD blocks</div>

  <ip-block *ngFor="let block of column; let index = index" cdkDrag [cdkDragData]="block" [block]="block"
    [index]="index" [column]="column" [cdkDragDisabled]="disableBlockDrag$(block) | async">
    <button mat-icon-button class="move" cdkDragHandle i18n-matTooltip="block|Change Block order"
      matTooltip="Change Block order" *ngIf="!(disableBlockDrag$(block) | async)">
      <mat-icon i18n-aria-label="block|Move block" aria-label="Move block" inline>pan_tool</mat-icon>
    </button>
  </ip-block>
</div>

<ng-content select=".move"></ng-content>
<div class="tools" fxLayoutGap="0.25rem">
  <button mat-icon-button class="edit" color="primary" i18n-matTooltip="structure|Change structure settings"
    matTooltip="Change structure settings">
    <mat-icon i18n-aria-label="structure|Edit structure" aria-label="Edit structure" inline>edit</mat-icon>
  </button>
  <button mat-icon-button (click)="duplicateStructure($event)" i18n-matTooltip="structure|Duplicate structure"
    matTooltip="Duplicate structure">
    <mat-icon i18n-aria-label="structure|Duplicate structure" aria-label="Duplicate structure" inline>file_copy
    </mat-icon>
  </button>
  <button mat-icon-button color="warn" (click)="removeStructure($event)" i18n-matTooltip="structure|Delete structure"
    matTooltip="Delete structure">
    <mat-icon i18n-aria-label="structure|Remove Structure" aria-label="Remove Structure" inline>delete_forever
    </mat-icon>
  </button>
</div>
