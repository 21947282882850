import { Component, Input, HostBinding } from '@angular/core';
import { SpacerBlock } from '../../classes/Elements';
import { createWidthHeight } from '../../utils';

@Component({
  selector: 'ip-spacer',
  template: '',
  styles: [
    `
      :host {
        display: table;
        width: 100%;
        transition: all 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      }
    `
  ]
})
export class SpacerComponent {
  @Input() block: SpacerBlock = new SpacerBlock();

  @HostBinding('style.height')
  get height() {
    return createWidthHeight(this.block.options.height);
  }

  constructor() { }
}
