<div fxLayout="column" style="height: 100%;">
  <h2 mat-dialog-title i18n="templates|Modal template header">Custom Templates</h2>
  <div class="mat-typography template-list-wrapper" fxLayout fxLayoutGap="1rem" fxFlex>
    <mat-list fxFlex="200px" dense>
      <mat-list-item (click)="chageCategory('latest')" [ngClass]="{active: (chooseCategory$ | async) === 'latest'}"
        i18n="templates|category name">
        Latest
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item *ngFor="let list of getTemplatesList()" (click)="chageCategory(list.category)"
        [ngClass]="{active: (chooseCategory$ | async) === list.category}" i18n="templates|category name">
        {{list.category}}
      </mat-list-item>
    </mat-list>

    <ng-container *ngIf="currentCategoryTemplates$ | async as templates;else emptyCategory">
      <cdk-virtual-scroll-viewport autosize fxFlex>
        <div class="template-list">
          <div *cdkVirtualFor="let template of templates" (click)="chooseTemplate(template)"
            i18n-matTooltip="templates|Click to choose message" matTooltip="Click to choose">
              <div [class.template-list-item]="csdTemplate !== template" [class.template-list-item-selected]="csdTemplate === template">
              <img [src]="template.thumbPath" [alt]="template.title" />
              <div class="description">
                <h3>{{template.title}}</h3>
              </div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close i18n="actions|Undo">Undo</button>
    <button mat-raised-button (click)="startBuilding()" color="primary" [disabled]="!csdTemplate" i18n="actions|Start">
      Start
    </button>
  </mat-dialog-actions>
</div>


<ng-template #emptyCategory>
  <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="empty-category-notification">
    <mat-icon>notifications</mat-icon>
    <h2 i18n="templates|No templates in current category message">This category is empty!</h2>
  </div>
</ng-template>