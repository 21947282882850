import { Observable, iif, throwError } from 'rxjs';

import { IpUserMiddlewaresService, TExportType } from './ip-middlewares.service';
import { IUserTemplate } from '../interfaces';
import { deferOf } from '../utils';

const NOT_ALLOWED = 'This feature is disabled in free version.';

export default class FreeUsersMiddleware extends IpUserMiddlewaresService {
  constructor() {
    super();
    // tslint:disable-next-line: no-console
    console.warn('You use the Regular License of Angular Email Builder, some limitations are applied. More info on https://wlocalhost.org');
  }

  chooseTemplate(): Observable<{ category: string, template: IUserTemplate }> {
    throw new Error(NOT_ALLOWED);
  }

  exportFile(type: TExportType): Observable<TExportType> {
    return iif(() => type === 'json', deferOf(type), throwError(new Error(NOT_ALLOWED)));
  }

  importFile(file: File): Observable<File> {
    return iif(() => file.type === 'application/json', deferOf(file), throwError(new Error(NOT_ALLOWED)));
  }
}
