import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Observable, Subject, Subscription, BehaviorSubject, empty, EMPTY, merge, combineLatest} from 'rxjs';
import {IPEmail, IpEmailBuilderService, IpUserRestApiService} from '../../../core-email-builder/src/public_api';
import {SendyService} from '../auth/api/sendy.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SaveAsModalComponent} from '../modals/save-as-modal/save-as-modal.component';
import {exhaustMap, map, takeUntil, switchMap, debounceTime, tap, first, take, filter} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, ParamMap, Router, RouterEvent} from '@angular/router';
import { User } from '../auth/user.model';
import {TestModalComponent} from '../modals/test-modal/test-modal.component';
import {SegmentData} from '../modals/save-as-modal/SegmentData';
import {SaveResultData} from '../modals/save-as-modal/save-result-data';
import { IpEmailObjectStoreService } from '../../../core-email-builder/src/lib/ip-email-object-store.service';
import { Template } from 'src/models/template';
import { IpUserInterfaceService } from '../../../core-email-builder/src/lib/user-interface.service';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmailList} from '../modals/save-as-modal/EmailListData';
import { Campaign, isCampaign } from 'src/models/campaign';
import { ItemType } from 'src/models/item';
import { SendModalComponent } from 'src/modals/send-modal/send-modal.component';
import { SendModalResponse } from 'src/modals/send-modal/send-modal-response';
import { SaveAsModalResult } from 'src/modals/save-as-modal/data/save-as-modal-result';
import { SaveAsModalParams } from 'src/modals/save-as-modal/data/save-as-modal-params';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TitleService } from 'src/services/title.service';
import { FooterValidationModalComponent } from 'src/modals/footer-validation-modal/footer-validation-modal.component';

@Component({
  selector: 'app-email-builder',
  templateUrl: './email-builder.component.html',
  styleUrls: ['./email-builder.component.scss']
})
export class EmailBuilderComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<any>();
  private saveAsModal: MatDialogRef<SaveAsModalComponent, any>;
  private footerValidationModal: MatDialogRef<FooterValidationModalComponent, any>;

  public lastSavedDate: string;

  private currentEmailSource = new BehaviorSubject<IPEmail>(new IPEmail());
  public currentEmail$ = this.currentEmailSource.asObservable();

  private isEditingSource = new BehaviorSubject<boolean>(false);
  public isEditing$ = this.isEditingSource.asObservable();

  private emailItemSource = new BehaviorSubject<Campaign | Template>(null);
  public emailItem$ = this.emailItemSource.asObservable();

  private isInitializedSource = new BehaviorSubject<boolean>(false);
  public isInitialized$ = this.isInitializedSource.asObservable();

  private emailChangesSubscription: Subscription;

  constructor(
    private ngb: IpEmailBuilderService,
    private emailObjectStore: IpEmailObjectStoreService,
    private titleService: TitleService,
    private userInterface: IpUserInterfaceService,
    private sendyService: SendyService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      const emailItem = data.emailItem;
      this.emailItemSource.next(emailItem);
    });

    combineLatest([
      this.emailItem$,
      this.isEditing$,
    ]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([emailItem, isEditing]) => {
      const isAutosaving = this.emailChangesSubscription && !this.emailChangesSubscription.closed;
      if (isEditing) {
        if (!isAutosaving) {
          this.emailChangesSubscription = this.emailObjectStore.currentEmailHasChanges$
              .pipe(
                debounceTime(500),
                switchMap(hasChanged => {
                  return hasChanged ? this.saveInternal(emailItem) : EMPTY;
                }),
                takeUntil(this.ngUnsubscribe)
            ).subscribe();
        }
      }
      else {
        if (isAutosaving) {
          this.emailChangesSubscription.unsubscribe();
        }
      }
    });

    this.emailItem$
        .pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe(emailItem => {
          if (emailItem && emailItem.email) {
            this.currentEmailSource.next(emailItem.email);
            this.isEditingSource.next(true);
          } else {
            this.currentEmailSource.next(new IPEmail());
            this.isEditingSource.next(false);
          }
        });
  }

  async generateHtml(): Promise<string>
  {
    const response = await this.ngb.createHTMLTemplate$().pipe(first()).toPromise();
    return response.html;
  }

  async saveInternal(item: Campaign | Template) : Promise<SaveResultData> {

    const html = await this.generateHtml();
    if(html.includes('href="[unsubscribe]"')){
      //Generate latest email and HTML for Template or Campaign and update cache
      item.html = html;
      item.email = this.ngb.Email;
      this.emailItemSource.next(item);
      
      const savePromise = isCampaign(item) ?
            this.sendyService.saveCampaign(item) :
            this.sendyService.saveTemplate(item);

      const result = await savePromise;

      this.lastSavedDate = new Date().toLocaleTimeString();

      return result;
    }else{
      
      const type = (item.id=='0') ? 'new' :'existing'
      this.footerValidationModal = this.dialog.open<FooterValidationModalComponent>(FooterValidationModalComponent, {
        autoFocus: true,
        width: '450px',
        data: {
          saveType: type
        }
      });
      this.footerValidationModal.afterClosed().subscribe(result => {
        if(result == "reload"){
          location.reload();
        } else {
          this.save();
        }
      });
    }
    
  }

  private createItem(metadata: SaveAsModalResult) : Campaign | Template {
    const emailItem = this.emailItemSource.getValue();
    const item = emailItem.itemType === 'template' ?
    {
      id: emailItem.id,
      itemType: emailItem.itemType,
      html: '',
      email: this.ngb.Email,
      title: metadata.title
    } as Template :
    {
      id: emailItem.id,
      itemType: emailItem.itemType,
      html: '',
      email: this.ngb.Email,
      title: metadata.title,
      fromName: metadata.fromName,
      fromEmail: metadata.fromEmail,
      replyTo: metadata.replyTo,
    } as Campaign;

    return item;
  }

  async save() : Promise<void> {
    let emailItem = this.emailItemSource.getValue();
    this.saveAsModal = this.dialog.open<SaveAsModalComponent, SaveAsModalParams>(SaveAsModalComponent, {
      autoFocus: true,
      width: '450px',
      data: {
        itemType: emailItem.itemType,
        title: this.ngb.Email.general.name
      }
    });

    const saveAsResult = await this.saveAsModal.afterClosed()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .toPromise<SaveAsModalResult>();

    if (saveAsResult) {
      this.ngb.Email.general.name = saveAsResult.title;

      emailItem = this.createItem(saveAsResult);
      const saveResult = await this.saveInternal(emailItem);
      if (saveResult.campaign_id !== '0'){
        emailItem.id = saveResult.campaign_id;
        this.emailItemSource.next(emailItem);
        window.history.replaceState(null, "null", `builder/${emailItem.itemType}/${saveResult.campaign_id}`);
      }
    }
  }

  sendCampaign() : void {
    const sendModal = this.dialog.open(SendModalComponent, {
      width: '450px',
      height: 'auto'
    });

    sendModal.afterClosed()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).toPromise<SendModalResponse>()
       .then(response => {
         if (response) {

          const campaign = this.emailItemSource.getValue() as Campaign;

            return this.sendyService
                      .saveCampaign(campaign, response.listIds, response.segmentIds, response.sendDate)
                      .then(() => {
                        this.snackBar.open(
                          `${campaign.title} campaign sent successfully.`,
                          null,
                          { duration: 5 * 1000});

                          return this.sendyService.getCampaign(campaign.id)
                                     .then(c => {
                                      
                                        if( c.sent !== '' )
                                        {
                                          campaign.sendDate = new Date(c.sent);
                                        }else {
                                          campaign.sendDate = new Date();
                                        }
                                        
                                        campaign.isSent = true;
                                        
                                        this.emailItemSource.next(campaign);
                                     });
                      });
         }
      });
  }


  testCampaign(): Promise<void> {
    const testModal = this.dialog.open(TestModalComponent, {
      autoFocus: true,
    });

    return testModal.afterClosed()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).toPromise<string>()
       .then(testEmailAddress => {
        if (testEmailAddress) {
          this.sendyService.sendTestEmail(testEmailAddress, this.emailItemSource.getValue().id)
              .then(sent => {
                this.snackBar.open(
                  `Test email for ${this.ngb.Email.general.name} campaign sent.`,
                  null,
                  { duration: 5 * 1000});
          });
        }
      });
  }


  ngOnDestroy()
  {
    this.ngUnsubscribe.complete();
    if (this.emailChangesSubscription && !this.emailChangesSubscription.closed) {
      this.emailChangesSubscription.unsubscribe();
    }
  }
}
