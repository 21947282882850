<section>
  <form class="login-form" fxLayout="column" fxLayoutAlign="center center" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <img class="login-logo" src="/assets/SendyCreator.png" />
    <p class="login-description">Create your Sendy Campaigns faster with Sendy Creator. Use this simple drag and drop
      builder to layout and design your email with ease.</p>
    <mat-form-field>
      <input type="email" matInput placeholder="Your Email" ngModel name="email">
    </mat-form-field>
    <mat-form-field>
      <input type="password" matInput placeholder="Your Password" ngModel name="password">
    </mat-form-field>
    <button type="submit" mat-raised-button color="primary">Submit</button>
  </form>
</section>