<mat-toolbar color="primary" class="njea-blue">
  <mat-toolbar-row>
    <button *ngIf="isLoggedIn$ | async" mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1 fxFlex="fill">Sendy Email Creator {{ pageTitle$ | async }} </h1>
    <div *ngIf="isLoggedIn$ | async">
      <a mat-button [routerLink]="'/'"> New Campaign </a>
    </div>
    <div>
      <a mat-raised-button color="primary" class="white-border" (click)="onNavigateSendy()">Sendy
        Dashboard
        <mat-icon>launch</mat-icon></a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
  <!--Marked sidenav as closed to start otherwise
     you have to click twice to open the first time
  -->
  <mat-sidenav opened="false" #sidenav>
    <mat-nav-list>
      <div>
        <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/builder/campaign'">New Campaign</a>
        <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/campaigns'">Campaigns</a>
        <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/templates'">Templates</a>
        <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/builder/template'">New Template</a>
        <!-- <a mat-list-item (click)="collector.showDialog()">Report an issue</a> -->
        <a mat-list-item (click)="onLogout()">Logout</a>
      </div>
      <span class="version-style"> v0.1.3</span>


    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>