import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { of, animationFrameScheduler, Subject, BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import {
  catchError, distinctUntilChanged, tap, subscribeOn, distinctUntilKeyChanged, map, shareReplay, distinct, filter
} from 'rxjs/operators';

import { IStructure, IUserTemplateCategory, IUserTemplate } from './interfaces';
import { TBlocks } from './classes/Elements';
import { TemplateListDialogComponent } from './components/template-list-dialog/template-list-dialog.component';
import { ConfirmDialogComponent } from './components/dialog.component';
import { IpEmailObjectStoreService } from './ip-email-object-store.service';
import { ImportDialogComponent } from './components/import-dialog/import-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { IPEmail } from './classes/DefaultEmail';

@Injectable()
export class IpUserInterfaceService {
  private readonly _currentEditingStructure$ = new BehaviorSubject<IStructure>(null);
  private readonly _currentEditingBlock$ = new BehaviorSubject<TBlocks>(null);
  private readonly _editGeneralSettings$ = new Subject<'structure' | 'block' | 'general'>();
  private readonly _currentTabIndex$ = new BehaviorSubject<number>(0);
  public readonly activeMatProgress$ = new ReplaySubject<boolean>();

  public readonly currentEditingStructure$ = this._currentEditingStructure$.pipe(
    subscribeOn(animationFrameScheduler),
    distinctUntilChanged(),
    catchError(() => of(null as IStructure)),
    // tap(editStructure => console.log(editStructure)),
    shareReplay()
  );
  public readonly currentEditingBlock$ = this._currentEditingBlock$.pipe(
    subscribeOn(animationFrameScheduler),
    distinctUntilChanged(),
    catchError(() => of(null as TBlocks)),
    shareReplay()
  );
  public readonly editGeneralSettings$ = this._editGeneralSettings$.pipe(
    subscribeOn(animationFrameScheduler),
    tap((settings) => {
      //Dont autonavigate to settings when clicking the background, its annoying
      if (settings !== "general") {
        this.changeTabIndex(2)
      }
    }),
    distinctUntilChanged(),
    shareReplay()
  );
  public readonly currentTabIndex$ = this._currentTabIndex$.pipe(
    subscribeOn(animationFrameScheduler),
    distinctUntilChanged(),
  );
  public readonly cdkDropListConnectedTo$ = this.emailObjectStore.emailStructuresAsObservable$.pipe(
    // tap(structures => console.log('Structures Length', structures.length)),
    map(structures => structures.reduce((prev, { elements }, structureKey) => {
      prev.push(...elements.map((_, columnKey) => `column-droplist-${columnKey}-${structureKey}`));
      return prev;
    }, [])),
    distinct(),
    distinctUntilKeyChanged('length'),
    // tap(dropList => console.log('Drop List', dropList)),
    shareReplay()
  );

  constructor(
    private emailObjectStore: IpEmailObjectStoreService,
    private matDialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar
  ) { }

  editBlock(block: TBlocks) {
    this._currentEditingStructure$.next(null);
    this._currentEditingBlock$.next(block);
    this._editGeneralSettings$.next('block');
  }

  editStructure(structure: IStructure) {
    this._currentEditingBlock$.next(null);
    this._editGeneralSettings$.next('structure');
    this._currentEditingStructure$.next(structure);
  }

  editGeneralSettings() {
    this.resetElements();
    this._editGeneralSettings$.next('general');
  }

  changeTabIndex(index: number) {
    this._currentTabIndex$.next(index);
  }

  confirmDialog$() {
    return this.matDialog.open(ConfirmDialogComponent).afterClosed().pipe(map(res => res === '1'));
  }

  templatesListDialog$(templateList: IUserTemplateCategory[]) {
    return this.matDialog.open<
      TemplateListDialogComponent,
      IUserTemplateCategory[],
      { category: string, template: IUserTemplate }
    >(TemplateListDialogComponent, {
      data: templateList,
      width: '60vw', maxWidth: '800px',
      height: '70vh', maxHeight: '900px'
    }).afterClosed().pipe(filter(tmpl => Boolean(tmpl)));
  }

  importDialog$(): Observable<IPEmail> {
    return this.bottomSheet.open(ImportDialogComponent, {
      ariaLabel: 'Browse media',
    }).afterDismissed();
  }

  notify(msg: string, close = 'dismiss', duration = 3000) {
    return this.snackBar.open(msg, close, { duration });
  }

  currentEditingBlock() {
    return this._currentEditingBlock$.getValue();
  }

  currentEditingStructure() {
    return this._currentEditingStructure$.getValue();
  }

  currentStructureEqualWith(structure: IStructure) {
    return this.currentEditingStructure() === structure;
  }

  currentBlockEqualWith(block: TBlocks) {
    return this.currentEditingBlock() === block;
  }

  currentStructureContainsActiveBlock(structure: IStructure) {
    return structure.elements.some(elems => elems.some(block => block === this.currentEditingBlock()));
  }

  resetElements() {
    this._currentEditingStructure$.next(null);
    this._currentEditingBlock$.next(null);
  }

  reset() {
    this.resetElements();
    this.changeTabIndex(0);
  }
}
