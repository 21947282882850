import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IWidthHeight } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-width-height',
  template: `
    <div class="group" [ngClass]="{ three: showAutoSlider() }">
      <mat-form-field appearance="outline">
        <mat-label>{{ getLabel() }}</mat-label>
        <input
          matInput
          [(ngModel)]="model.value"
          [disabled]="disableValueField() || disabled"
          type="number"
          [placeholder]="getLabel()"
          (input)="markForCheck()"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Size Settings|Size Unit">Unit</mat-label>
        <mat-select
          [disabled]="model.auto || disabled"
          [(value)]="model.unit"
          (selectionChange)="markForCheck()"
          disableRipple
        >
          <mat-option *ngFor="let unit of getUnits()" [value]="unit" i18n>
            {{ getUnitLabel(unit) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle
        style="margin-top: -20px;"
        *ngIf="showAutoSlider()"
        [checked]="model.auto"
        (change)="toggleChange($event)"
        [disabled]="disabled"
        i18n="Size Settings|Size Auto"
      >
        Auto
      </mat-slide-toggle>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidthHeightComponent {
  @Input() model: IWidthHeight;
  @Input() label: string;
  @Input() disabled = false;

  constructor(private emailObject: IpEmailObjectStoreService) { }

  private units: Map<string, string> = new Map([
    ['%', 'Percents'],
    ['px', 'Pixels'],
    ['contain', 'Contain'],
    ['cover', 'Cover'],
  ]);

  markForCheck() {
    this.emailObject.markForCheck();
  }

  getLabel() {
    return this.label;
  }

  toggleChange({ checked }) {
    this.model.auto = checked;
    this.markForCheck();
  }

  getUnits() {
    return this.model.units || ['%', 'px'];
  }

  disableValueField() {
    return this.model.auto || ['%', 'px'].indexOf(this.model.unit) === -1;
  }

  showAutoSlider() {
    return this.model.hasOwnProperty('auto');
  }

  getUnitLabel(unit: string): string {
    return this.units.get(unit);
  }
}
