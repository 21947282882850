import {Injectable} from '@angular/core';
import {AuthData} from '../auth-data.model';
import {User} from '../user.model';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, fromEventPattern, Observable, throwError} from 'rxjs';
import {catchError, retry, shareReplay} from 'rxjs/operators';
import {sha512} from 'js-sha512';
import {TemplateData} from './template-data.model';
import {APICall} from './api-call.model';
import {IPEmail, IUserTemplateCategory} from '../../../../core-email-builder/src/public_api';
import {Router} from '@angular/router';
import {SaveResultData} from '../../modals/save-as-modal/save-result-data';
import {environment} from '../../environments/environment';
import { EmailList } from '../../modals/save-as-modal/EmailListData';
import { Template } from 'src/models/template';
import { Campaign } from 'src/models/campaign';
import {StatsDashboardData} from '../../reports/stats-component/StatsDashboardData';

@Injectable({
  providedIn: 'root'
})
export class SendyService {
  private user: User;
  private apiUrl = environment.apiURL;

  private isLoggedInSource = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router) {
    this.checkSession();
  }

  login(authData: AuthData): Promise<boolean> {
    const formData = new FormData();
    formData.append('email', authData.email);
    formData.append('password', sha512.create().update(authData.password + 'PectGtma').toString());
    return this.http
      .post<User>(this.apiUrl + 'api/revolt/authenticate.php', formData)
      .toPromise()
      .then(u => {
        this.user = u;
        if (this.user && this.user.apiKey) {
          sessionStorage.setItem('user', JSON.stringify(this.user));
          this.isLoggedInSource.next(true);

          return true;
        }
        alert('incorrect Username or Password.');
        return false;
      })
      .catch(e => {
        alert('error!!');
        return false;
      });
  }

  logout() {
    this.isLoggedInSource.next(false);
    sessionStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  getTemplates(): Promise<TemplateData[]> {
    const formData = new FormData();
    formData.append('api_key', this.user.apiKey);
    formData.append( 'app_id', String(this.user.appId));
    return this.http
      .post<TemplateData[]>(this.apiUrl + 'api/revolt/get-template-list.php', formData)
      .toPromise()
      .then(t => {
        return t;
      });
  }

  getCampaigns(): Promise<TemplateData[]> {
    const formData = new FormData();
    formData.append('api_key', this.user.apiKey);
    formData.append( 'app_id', String(this.user.appId));
    return this.http
      .post<TemplateData[]>(this.apiUrl + 'api/revolt/get-campaigns.php', formData)
      .toPromise();
  }

  getSegments(): Promise<EmailList[]> {
    const formData = new FormData();
    formData.append('api_key', this.user.apiKey);
    formData.append( 'app_id', String(this.user.appId));
    return this.http
      .post<EmailList[]>( this.apiUrl + 'api/revolt/get-segments.php', formData)
      .toPromise()
      .then(s => {
        return s;
      });
  }

  saveTemplate(template: Template): Promise<SaveResultData>{
    console.log(template.id);
    const formData = new FormData();
    formData.append('api_key', this.user.apiKey);
    formData.append( 'app_id', String(this.user.appId));
    formData.append( 'title', template.title);
    formData.append( 'html_text', template.html);
    formData.append( 'ipemail', JSON.stringify(template.email));

    if (template.id !== '0'){
      formData.append( 'id', template.id);
    }

    return this.http
      .post<SaveResultData>(this.apiUrl + 'api/revolt/save-template.php', formData)
      .toPromise();
  }

  saveCampaign(campaign: Campaign, listIds: string[] = [], segmentIds: string[] = [], scheduled: Number = 0){
    console.log(campaign.id);
    const formData = new FormData();
    formData.append('api_key', this.user.apiKey);
    formData.append('app_id', String(this.user.appId));
    formData.append('from_email', campaign.fromEmail);
    formData.append('from_name', campaign.fromName);
    formData.append('reply_to', campaign.replyTo);
    formData.append('title', campaign.title);
    formData.append('html_text', campaign.html);
    formData.append('ipemail', JSON.stringify(campaign.email));
    if ( campaign.id !== '0'){
      formData.append( 'id', campaign.id );
    }

    let shouldSend = false;
    if (listIds && listIds.length > 0) {
      formData.append('list_ids', listIds.join(','));
      shouldSend = true;
    }

    if (segmentIds && segmentIds.length > 0) {
      formData.append('segment_ids', segmentIds.join(','));
      shouldSend = true;
    }

    if (shouldSend) {
      formData.append('send_campaign', '1');
    }
    if (scheduled !== 0){
      formData.append('scheduled', scheduled.toString());
    }

    return this.http
      .post<SaveResultData>(this.apiUrl + 'api/revolt/save-campaign.php', formData)
      .toPromise();
  }

  sendTestEmail( email: string, id: string ){
    const formData = new FormData();
    formData.append( 'test_email', email );
    formData.append( 'campaign_id', id );
    formData.append( 'app_id', '1' );
    formData.append( 'user_id', '1' );
    return this.http
      .post( this.apiUrl + 'includes/revolt/test-send.php', formData)
      .toPromise()
      .then(t => {
        return t;
      });
  }

  getCampaign( id: string ): Promise<TemplateData> {
    return this.http
      .get<TemplateData>( this.apiUrl + 'api/revolt/get-email.php?id=' + id)
      .toPromise();
  }

  duplicateCampaign(id: string, 
    fromEmailOverride: string,
    fromNameOverride: string,
    replyToOverride: string,
    titleOverride: string,
    ){
    const user = this.getUser();
    const formData = new FormData();
    formData.append( 'campaign_id', id );
    formData.append( 'app_id', String(user.appId) );
    formData.append( 'api_key', user.apiKey );

    if (fromEmailOverride) {
      formData.append('from_email', fromEmailOverride);
    }

    if (fromNameOverride) {
      formData.append('from_name', fromNameOverride);
    }

    if (replyToOverride) {
      formData.append('reply_to', replyToOverride);
    }

    if (titleOverride) {
      formData.append('title', titleOverride);
    }

    return this.http
      .post<SaveResultData>( this.apiUrl + 'api/revolt/duplicate.php', formData)
      .toPromise();
  }

  getTemplate( id: string ): Promise<IPEmail> {
    return this.http
      .get<IPEmail>( this.apiUrl + 'api/revolt/get-template.php?id=' + id)
      .toPromise();
  }

  deleteTemplate(id: string){
    const user = this.getUser();
    const formData = new FormData();
    formData.append( 'template_id', id );
    formData.append('app_id', String(user.appId));
    return this.http
      .post<boolean>( this.apiUrl + 'api/revolt/delete-template.php', formData)
      .toPromise();
  }

  getUserTemplates(): Observable<IUserTemplateCategory[]> {
    const user = this.getUser();
    const formData = new FormData();

    formData.append('app_id', String(user.appId));
    formData.append('api_key', user.apiKey);

    return this.http
    .post<IUserTemplateCategory[]>(environment.apiURL + 'api/revolt/get-templates.php', formData);
  }

  getEmailStats(date: string): Promise<StatsDashboardData>{
    const formData = new FormData();

    formData.append('date', date);

    return this.http
      .post<StatsDashboardData>( this.apiUrl + 'api/revolt/get-email-stats.php', formData)
      .toPromise();
  }

  getSendCount(lists: string, segments: string): Promise<Number>{
    const user = this.getUser();
    const formData = new FormData();
    formData.append('api_key', user.apiKey);
    formData.append('lists', (lists!='' ? lists : '0'));
    formData.append('segments', (segments!='' ? segments : '0'));

    return this.http
      .post<Number>( this.apiUrl + 'api/revolt/get-send-counts.php', formData)
      .toPromise();
  }

  getUser(): User {
    return {...this.user};
  }

  private checkSession(){
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if (this.user && this.user.apiKey) {
      this.isLoggedInSource.next(true);
    }
  }
}
