import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IP_CONFIG, IP_DEFAULT_CONFIG } from './tokens';
import { IForRootConf } from './interfaces';
import { IpUserInterfaceService } from './user-interface.service';

@Injectable({
  providedIn: 'root'
})
export class IpInterceptorInterceptor implements HttpInterceptor {

  constructor(@Inject(IP_CONFIG) private userConfig: IForRootConf, private userInterface: IpUserInterfaceService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('https://converter.njeasites.net')) {
      //const notify = this.userInterface.notify('Loading, please wait ...', null, null);
      const { xApiKey } = { ...IP_DEFAULT_CONFIG, ...this.userConfig };
      return next.handle(request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'X-Api-Key': xApiKey
        },
        responseType: 'json'
      })).pipe(
        tap((res: HttpResponse<any>) => {
          if (res.type === HttpEventType.Response) {
            //notify.dismiss();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
