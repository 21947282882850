import {
  IStructure,
  TStructreTypes,
  IStructureOptions,
  IStructureColumnOptions
} from '../interfaces';
import { TBlocks } from '../classes/Elements';
import { defaultsDeep } from '../utils';

export const defaultColumnsOptions: IStructureColumnOptions = {
  background: {
    color: 'transparent'
  },
  border: {
    width: 0,
    color: '#cccccc',
    radius: 0,
    style: 'solid'
  },
  verticalAlign: 'top'
};

export class Structure implements IStructure {
  columns = 1;
  readonly id = Date.now();
  options: IStructureOptions = {
    border: {
      color: '#cccccc',
      style: 'solid',
      width: 0,
      radius: 0
    },
    background: {
      color: '#ffffff',
      url: '',
      repeat: 'repeat',
      size: {
        value: 100,
        unit: 'px',
        auto: true,
        units: ['px', '%', 'cover', 'contain']
      }
    },
    padding: {
      top: 4,
      right: 4,
      bottom: 4,
      left: 4
    },
    margin: {
      top: 0,
      bottom: 0
    },
    gaps: [4, 4]
  };

  constructor(
    readonly type: TStructreTypes = 'cols_1',
    public elements: TBlocks[][] = [],
    options?: IStructureOptions
  ) {
    if (!elements.length) {
      if (['cols_2', 'cols_12', 'cols_21'].includes(type)) {
        this.columns = 2;
      } else if (type === 'cols_3') {
        this.columns = 3;
      } else if (type === 'cols_4') {
        this.columns = 4;
      }
    }

    const columns: IStructureColumnOptions[] = Array.from(
      { length: this.columns },
      () => defaultColumnsOptions
    );

    let columnsWidth = [1];
    if (type === 'cols_21') {
      columnsWidth = [4, 6];
    } else if (type === 'cols_12') {
      columnsWidth = [6, 4];
    } else if (type === 'cols_2') {
      columnsWidth = [5, 5];
    } else if (type === 'cols_3') {
      columnsWidth = [3.33, 3.33, 3.33];
    } else if (type === 'cols_4') {
      columnsWidth = [2.5, 2.5, 2.5, 2.5];
    }

    this.options = defaultsDeep(options, this.options, {
      columns,
      columnsWidth
    });
  }
}
