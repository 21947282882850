import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SendyService} from '../../auth/api/sendy.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TemplateData } from 'src/auth/api/template-data.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { DeleteModalComponent } from 'src/modals/delete-modal/delete-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<TemplateData>;
  displayedColumns = ['id', 'name', 'edit', 'delete'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private isInitializedSource = new BehaviorSubject<boolean>(false);
  public isInitialized$ = this.isInitializedSource.asObservable();
  private deleteModal: MatDialogRef<DeleteModalComponent, any>;

  constructor(
    private sendyService: SendyService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {
  }

  ngAfterViewInit(): void {
    this.isInitialized$
        .subscribe((isInitialized) => {
          if (isInitialized) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
      });
  }

  ngOnInit(): void {
    this.sendyService.getTemplates()
      .then(res => {
       this.dataSource = new MatTableDataSource<TemplateData>(res);
       this.isInitializedSource.next(true);
    });
  }

  editTemplate( id: string ){
    this.router.navigateByUrl(`/builder/template/${id}`);
  }

  deleteTemplate( id: string, name: string ){
    this.deleteModal = this.dialog.open<DeleteModalComponent>(DeleteModalComponent, {
      autoFocus: true,
      width: '450px',
      data: {
        title: name
      }
    });
    let deleteResults: boolean;
    this.deleteModal.afterClosed().subscribe(
      data => {
        if(data){
          this.sendyService.deleteTemplate(id)
          .then(res => {
            console.log(res);
            if(res){
              this.reloadComponent();
            }
          });
        }
      });
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/templates/']);
}
}
