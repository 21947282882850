import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TAlign, TVerticalAlign } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-align',
  template: `
    <mat-form-field appearance="outline">
      <mat-label [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'vertical'" i18n="Align Settings|Vertical Align">Vertical Align</ng-container>
        <ng-container *ngSwitchDefault i18n="Align Settings|Align">Align</ng-container>
      </mat-label>
      <mat-select [(ngModel)]="currentModel" disableRipple [disabled]="disabled">
        <mat-option *ngFor="let position of getPositions()" [value]="position">
          {{ position }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      host: {
        display: block;
        width: 100%;
      }
      mat-form-field {
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlignComponent {
  @Input() model: { align?: TAlign; verticalAlign?: TVerticalAlign };
  @Input() mode: 'vertical' | 'horizontal' = 'horizontal';
  @Input() disabled: boolean;

  get currentModel() {
    return this.model.align || this.model.verticalAlign;
  }

  set currentModel(value: TAlign | TVerticalAlign) {
    if (this.model.align) {
      this.model.align = value as TAlign;
    } else {
      this.model.verticalAlign = value as TVerticalAlign;
    }
    this.emailObject.markForCheck();
  }

  constructor(private emailObject: IpEmailObjectStoreService) { }

  getPositions(): TAlign[] | TVerticalAlign[] {
    if (this.mode === 'vertical') {
      return ['top', 'bottom'];
    }
    return ['left', 'center', 'right'];
  }
}
