import {
  Component,
  Input,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  DoCheck,
  HostListener,
  OnInit,
  HostBinding,
  OnDestroy
} from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { take, map, exhaustMap, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Structure, defaultColumnsOptions } from '../../classes/Structure';
import { TBlocks } from '../../classes/Elements';
import { createBorder } from '../../utils';
import { IpUserMiddlewaresService, IAddBlockEvent } from '../../user-middleware-service/ip-middlewares.service';
import { IpUserInterfaceService } from '../../user-interface.service';
import { IpEmailObjectStoreService } from '../../ip-email-object-store.service';

@Component({
  selector: 'ip-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated
})
export class StructureComponent implements DoCheck, OnInit, OnDestroy {
  @Input() structure = new Structure();
  @Input() index: number;

  private readonly componentIsDestroyed$ = new Subject();

  public isActive: boolean;
  public cdkDropListConnectedTo$ = this.userInterfaceService.cdkDropListConnectedTo$;

  public fr: number;
  public validate = function ({ rectangle }: ResizeEvent) {
    const colFr = +(rectangle.width / this.fr).toFixed(2);
    return colFr > 1 && colFr <= 10 - this.structure.columns;
  }.bind(this);

  // Don't close right panel on structure click
  @HostListener('click', ['$event']) onHostClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.editStructure();
  }

  @HostBinding('class.active') get isStructureActive() {
    return this.isActive;
  }

  constructor(
    private userInterfaceService: IpUserInterfaceService,
    private emailObjectStore: IpEmailObjectStoreService,
    private chRef: ChangeDetectorRef,
    private ipMiddlewaresService: IpUserMiddlewaresService
  ) { }

  removeStructure(event: Event) {
    event.stopPropagation();
    this.ipMiddlewaresService.removeStructure(this.index, this.structure).pipe(
      exhaustMap(() => this.userInterfaceService.confirmDialog$()),
      filter(removeAllowed => removeAllowed),
      take(1)
    ).subscribe(() => {
      this.emailObjectStore.removeStructure(this.index);
      this.userInterfaceService.resetElements();
    });
  }

  duplicateStructure(event: Event) {
    event.stopPropagation();
    this.ipMiddlewaresService.duplicateStructure(this.index, this.structure).pipe(
      map(() => this.emailObjectStore.duplicateStructure(this.index)),
      take(1)
    ).subscribe(newStructure => {
      this.editStructure(newStructure);
    });
  }

  dropNewBlock(event: IAddBlockEvent, column: TBlocks[]) {
    if (event.previousContainer === event.container) {
      this.emailObjectStore.addBlock(event, column);
    } else {
      this.ipMiddlewaresService.addBlock(event, column).pipe(
        map(newData => this.emailObjectStore.addBlock(newData.event, newData.column))
      ).subscribe(() => {
        // this.userInterfaceService.editBlock(newBlock);
        this.chRef.markForCheck();
      });
    }
  }

  disableBlockDrag$(block: TBlocks) {
    return this.ipMiddlewaresService.disableBlockDragWithinEmailBody(block).pipe(take(1));
  }

  editStructure(structure = this.structure) {
    this.ipMiddlewaresService.editStructure(structure).pipe(take(1)).subscribe(strctr => {
      this.userInterfaceService.editStructure(strctr);
    });
  }

  getResizeEdges(columnKey: number) {
    return {
      right: columnKey + 1 !== this.structure.columns,
      left: columnKey !== 0
    };
  }

  createColumnId(columnKey: number) {
    return `column-droplist-${columnKey}-${this.index}`;
  }

  getColumnStyles(columnKey: number) {
    const {
      options: { gaps = [4, 4], columns = [] }
    } = this.structure;
    const column = columns[columnKey] || defaultColumnsOptions;

    let verticalAlign = 'center';
    if (column.verticalAlign === 'bottom') {
      verticalAlign = 'flex-end';
    } else if (column.verticalAlign === 'top') {
      verticalAlign = 'flex-start';
    }

    return {
      padding: gaps.map(gap => `${gap}px`).join(' '),
      backgroundColor: column.background.color,
      placeSelf: `${verticalAlign} stretch`,
      ...createBorder(column.border)
      // ...createBackground(column.background)
    };
  }

  onResizeEnd({ rectangle }: ResizeEvent, key: number) {
    this.structure.options.columnsWidth[key] = +(
      rectangle.width / this.fr
    ).toFixed(2);
  }

  ngDoCheck() {
    if (this.isActive || this.userInterfaceService.currentStructureContainsActiveBlock(this.structure)) {
      this.chRef.markForCheck();
    }
  }

  ngOnInit() {
    // Add columns options in case of old template
    const { columns, columnsWidth } = this.structure.options;
    if (!columns || !columnsWidth) {
      const { type, elements, options } = this.structure;
      this.editStructure(new Structure(type, elements, options));
    }

    const width = this.emailObjectStore.Email.general.width.value;
    const [, horizontalGap] = this.structure.options.gaps;
    this.fr = (width - horizontalGap * this.structure.columns) / 10;

    this.userInterfaceService.currentEditingStructure$.pipe(
      map(currentEditingStructure => currentEditingStructure === this.structure),
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(isActive => {
      this.isActive = isActive;
    });
  }

  ngOnDestroy() {
    this.componentIsDestroyed$.next('');
    this.componentIsDestroyed$.complete();
  }
}
