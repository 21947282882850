import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { IpUserInterfaceService } from '../../user-interface.service';
import { IpEmailObjectStoreService } from '../../ip-email-object-store.service';

@Component({
  selector: 'ip-structure-settings',
  templateUrl: './structure-settings.component.html',
  styleUrls: ['./structure-settings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class StructureSettingsComponent {
  currentStructure$ = this.ipInternalService.currentEditingStructure$;

  constructor(
    private ipInternalService: IpUserInterfaceService,
    private emailObject: IpEmailObjectStoreService
  ) { }

  markForCheck() {
    this.emailObject.markForCheck();
  }
}
