import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { IpUserImageUploaderService } from '../user-image-uploader-service/user-image-uploader.service';
import { ImageBlock } from '../classes/Elements';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';
import { IBackground } from '../interfaces';
import { IpUserMiddlewaresService } from '../user-middleware-service/ip-middlewares.service';

@Component({
  selector: 'ip-image-upload',
  styles: [
    `
      :host {
        display: block;
      }
      .choosed-image {
        width: 100%;
        max-height: 60px;
        overflow: hidden;
        border-radius: 3px;
        line-height: 0;
      }
      .choosed-image img {
        width: 100%;
        height: 60px;
        object-fit: cover;
        object-position: center;
      }
      .size {
        font-size: 80%;
        color: #ccc;
      }
      mat-form-field {
        margin-top: 0.5rem;
      }
    `
  ],
  template: `
    <div fxLayout fxLayoutAlign="space-between center">
      <div
        class="image-info"
        [fxFlex]="source ? '30%' : '60%'"
        fxLayout
        fxLayoutGap="0.5em"
        fxLayoutAlign="center center"
      >
        <div *ngIf="source" class="choosed-image">
          <img [src]="source" />
        </div>
        <span *ngIf="!source" style="width: 100%;" i18n="Upload Image Settings|Upload an image">
          Upload an image
        </span>
      </div>
      <button
        type="button"
        (click)="browse()"
        [disabled]="browsing | async"
        color="primary"
        mat-stroked-button
        i18n="Upload Image Settings|Browse"
      >
        Browse
      </button>
    </div>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label i18n="Upload Image Settings|Image Source">Image Source</mat-label>
      <input
        matInput
        [(ngModel)]="source"
        type="url"
        (input)="markForCheck()"
      />
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadComponent implements OnInit {
  @Input() block: ImageBlock | IBackground;
  @Input() key = 'src';
  browsing = new Subject();

  constructor(
    private imageUpload: IpUserImageUploaderService,
    private emailObject: IpEmailObjectStoreService,
    private middleware: IpUserMiddlewaresService
  ) { }

  get source() {
    return this.block[this.key];
  }
  set source(value: string) {
    this.block[this.key] = value;
  }

  markForCheck() {
    this.emailObject.markForCheck();
  }

  async browse() {
    this.browsing.next(true);
    try {
      const path = await this.imageUpload.browse$().toPromise();
      if (path) {
        this.source = path;
        this.emailObject.markForCheck();
      }
    } finally {
      this.browsing.next(false);
    }
  }

  ngOnInit() {
    this.source = (<ImageBlock>this.block).src || (<IBackground>this.block).url;
  }
}
