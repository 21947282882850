import {
  Component,
  Input,
  HostListener,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';
import { TBlocksOptions } from '../classes/Elements';
// import { IpEmailBuilderService } from '../ip-email-builder.service';
// import { parseColor } from '../utils';

@Component({
  selector: 'ip-color',
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }
      mat-form-field {
        width: 100%;
      }
      .choose-color {
        display: block;
        height: 100%;
        width: 100%;
      }
      .current-color {
        width: 100%;
        height: 48px;
        border-radius: 5px;
        margin: 0.25em 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.12);
        transition: background-color 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      }
      .choose-color.transparent .current-color {
        opacity: 0.5;
        pointer-events: none;
      }
      color-chrome {
        position: absolute;
        top: calc(100% - 1em);
        right: 0;
        z-index: 9;
      }
    `
  ],
  template: `
    <div class="group" *ngIf="showTransparent">
      <mat-slide-toggle
        [disabled]="disabled"
        [(ngModel)]="transparent"
        (change)="makeTransparentColor()"
        i18n="Color settings|Make color transparet"
      >
        Transparent
      </mat-slide-toggle>
    </div>
    <div class="group two">
      <mat-form-field appearance="outline">
        <mat-label i18n="Color settings|Change color">Color</mat-label>
        <input
          matInput
          [disabled]="transparent || disabled"
          (focus)="showColorPicker = true"
          [(ngModel)]="currentColor"
          type="color"
          style="height:25px"
          (input)="changeColorHex($event.target.value)"
        />
      </mat-form-field>
      <!--
      <div class="choose-color" [class.transparent]="transparent || disabled">
        <div
          class="current-color"
          (mouseover)="showColorPicker = !transparent"
          [style.backgroundColor]="currentColor"
        ></div>
        <color-chrome
          [disableAlpha]="true"
          *ngIf="showColorPicker"
          (onChangeComplete)="changeColor($event)"
          [color]="currentColor"
        ></color-chrome>
      </div>
-->
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorComponent implements OnInit {
  @Input() options: TBlocksOptions & { color?: string; backgroundColor?: string };
  @Input() showTransparent: boolean;
  @Input() disabled = false;

  transparent = false;
  showColorPicker = false;

  private oldValue: string;

  constructor(private emailObject: IpEmailObjectStoreService) { }

  get currentColorModel() {
    return this.options.backgroundColor || this.options.color
  }

  set currentColorModel(hex: string) {
    if (this.options.backgroundColor) {
      this.options.backgroundColor = hex
    } else {
      this.options.color = hex
    }
  }

  get currentColor() {
    return this.transparent ? this.oldValue : this.currentColorModel;
  }
  set currentColor(hex: string) {
    this.currentColorModel = hex;
  }

  @HostListener('mouseleave') onHostMouseLeave() {
    this.showColorPicker = false;
  }

  changeColor(event: ColorEvent) {
    console.log(event);
    this.currentColor = event.color.hex;
    this.emailObject.markForCheck();
  }

  changeColorHex(hex:string) {
    console.log(hex);
    this.currentColor = hex;
    this.emailObject.markForCheck();
  }

  makeTransparentColor() {
    this.showColorPicker = false;
    if (this.transparent) {
      this.oldValue = this.currentColorModel;
      this.currentColor = 'transparent';
    } else {
      this.currentColor = this.oldValue;
    }
    this.emailObject.markForCheck();
  }

  ngOnInit() {
    if (this.currentColor === 'transparent') {
      this.transparent = true;
      this.oldValue = '#ffffff';
    }
    // this.changeContrastColor();
  }
}
