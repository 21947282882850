import { Component, Input } from '@angular/core';
import { SocialBlock } from '../../classes/Elements';
import {
  createLineHeight,
  createFont,
  createPadding,
  getAssetByPath
} from '../../utils';

@Component({
  selector: 'ip-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent {
  @Input()
  block: SocialBlock;

  getParentStyles() {
    const { color, font, lineHeight, padding } = this.block.options;

    return {
      color,
      ...createLineHeight(lineHeight),
      ...createFont(font),
      ...createPadding(padding)
    };
  }

  getLabelStyles() {
    const { innerPadding } = this.block.options;
    return { ...createPadding(innerPadding), lineHeight: 0 };
  }

  getSocialListStyles() {
    const { align } = this.block.options;
    return {
      display: 'flex',
      placeContent:
        (align === 'left' && 'flex-start') ||
        (align === 'right' && 'flex-end') ||
        align
    };
  }

  getSocialListClasses() {
    return `social-list ${this.block.options.mode}`;
  }

  getSocialNetworkIcon(network: string) {
    return getAssetByPath(`${network}.png`);
  }
}
