import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SendyService } from 'src/auth/api/sendy.service';
import { EmailList } from '../save-as-modal/EmailListData';
import { SendModalResponse } from './send-modal-response';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  templateUrl: './send-modal.component.html',
  styleUrls: ['./send-modal.component.scss']
})
export class SendModalComponent implements OnInit {
  @ViewChildren('segmentCheckbox') segmentCheckboxes: QueryList<MatCheckbox>;
  @ViewChildren('listCheckbox') listCheckboxes: QueryList<MatCheckbox>;
  @ViewChildren('picker') picker: any;

  checkedLists: string[] = [];
  checkedSegments : string[] = [];
  public disabled = false;
  public showSeconds = false;
  public minDate = new Date();
  public dateControl = new FormControl(new Date());
  public enableMeridian = true;
  public datePicker;

  private isInitializedSource = new BehaviorSubject<boolean>(false);
  public isInitialized$ = this.isInitializedSource.asObservable();

  masterList: EmailList;
  customLists: EmailList[];
  recipients: Number;

  listIds: string = '';

  constructor(
    public dialogRef: MatDialogRef<SendModalComponent>,
    private sendyService: SendyService
  ) {
  }
  
  ngOnInit(): void {
    this.sendyService.getSegments()
        .then(emailLists => {
          this.masterList = emailLists.find(e => e.name === 'Master List');
          this.customLists = emailLists.filter(e => e.name !== 'Master List');
          this.isInitializedSource.next(true);
      });
  }

  send() : void {
    let sendDate: Number;
    if(this.datePicker !== undefined){
      sendDate = new Date(this.datePicker.toUTCString()).getTime()/1000;
    }else{
      sendDate = 0;
    }
    
    this.dialogRef.close(
    {
      listIds: this.checkedLists,
      segmentIds: this.checkedSegments,
      sendDate: sendDate
    });
    
  }
  
  listSelected(event: MatCheckboxChange) {
    this.audienceSelected(event, this.checkedLists);

    const list = this.customLists.find(l => l.id.toString() === event.source.value);
    if (list) {
      list.segments.forEach(segment => {
        const segmentBox = this.segmentCheckboxes.find(s => s.value === segment.id.toString());
        segmentBox.checked = false;

        this.checkedSegments.splice(this.checkedSegments.indexOf(segment.id.toString()), 1);
      });
    }
  }

  segmentSelected(event: MatCheckboxChange) {
    this.audienceSelected(event, this.checkedSegments);
  }

  audienceSelected(event: MatCheckboxChange, cache: string[]) {
    this.audienceSelectedRaw(event.checked, event.source.value, cache);
  }

  audienceSelectedRaw(selected: boolean, id: string,  cache: string[]) {
    if (selected) {
      cache.push(id);
    } else {
      const index = cache.indexOf(id, 0);
      cache.splice(index, 1);
    }
    this.sendyService.getSendCount(this.checkedLists.toString().replace('"', ''), this.checkedSegments.toString().replace('"',''))
        .then(n => {
          this.recipients = n;
      });
  }
}
