import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { SocialBlock, TBlocksOptions } from '../../classes/Elements';
import { ISocialNetwork } from '../../interfaces';
import { getAssetByPath } from '../../utils';
import { IpUserInterfaceService } from '../../user-interface.service';
import { IP_SOCIAL_NETWORKS } from '../../tokens';
import { IpEmailObjectStoreService } from '../../ip-email-object-store.service';

@Component({
  selector: 'ip-block-settings',
  templateUrl: './block-settings.component.html',
  styleUrls: ['./block-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BlockSettingsComponent {
  block$ = this.internalService.currentEditingBlock$;

  constructor(
    private internalService: IpUserInterfaceService,
    private emailObject: IpEmailObjectStoreService,
    @Inject(IP_SOCIAL_NETWORKS) private networks: string[]
  ) { }

  hasProperty(options: TBlocksOptions, property: string) {
    return options.hasOwnProperty(property);
  }

  markForCheck() {
    this.emailObject.markForCheck();
  }

  socialNetworks(block: SocialBlock) {
    return this.networks.filter(link => !block.networks.find(({ name }) => name === link)).sort();
  }

  addSocialNetwork({ value, source }: MatSelectChange, block: SocialBlock) {
    block.networks.push({ name: value, href: '', label: '' });
    source.value = '';
    this.markForCheck();
  }

  removeSocialNetwork(network: ISocialNetwork, block: SocialBlock) {
    block.networks = block.networks.filter(n => n !== network);
    this.markForCheck();
  }

  getSocialIcon(network: string) {
    return getAssetByPath(`${network}.png`);
  }
}
