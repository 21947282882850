import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  deferOf,
  IEmailAndGoogleFonts,
  IIPEmail,
  IMjmlServerResponse,
  IpUserRestApiService,
  IUserModule,
  IUserTemplate,
  IUserTemplateCategory,
  Structure,
  TextBlock
} from '../../../core-email-builder/src/public_api';
import { Observable, pipe } from 'rxjs';
import { SendyService } from '../auth/api/sendy.service';
import { defaultModules } from '../modules';
import {environment} from '../environments/environment';
import {map, share, shareReplay} from 'rxjs/operators';

class TemplateData
{
    category: string;
    templateData: string[];
}


@Injectable({
  providedIn: 'root'
})
export class IpEmailService extends IpUserRestApiService {

  getAllUserModules$: Observable<IUserModule[]> = deferOf(defaultModules);
  getAllUserTemplates$ = this.sendyService.getUserTemplates();

  constructor(http: HttpClient, private sendyService: SendyService) {
    super(http);

    this.sendyService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.getAllUserTemplates$ = this.sendyService.getUserTemplates();
      }
    });
  }

  getUserTemplateData$(category: string, template: IUserTemplate) {
    if (template.templateData) {
      return deferOf(template);
    }
    console.log('getting template object');
    return this.http.get<IIPEmail>(environment.apiURL + 'api/revolt/get-templates.php', {
      params: { category, template: template.title }
    }).pipe(
      map(email => ({ ...template, templateData: email }) as IUserTemplate)
    );
  }

  getUserImages$(): Observable<string[]>
  {
    const user = this.sendyService.getUser();

    return this.http.get<string[]>(environment.imageGetPath + `?app_id=${user.appId}`);
  }


  userImageUpload$(body: FormData, uploadImagePath: string): Observable<HttpEvent<{ success: boolean; path: string; message?: string }>> {
    const user = this.sendyService.getUser();

    body.append('app_id', user.appId.toString());
    return this.http.request('POST', uploadImagePath, {
      body, reportProgress: true, responseType: 'json', observe: 'events',
    });
  }

  // createHTMLTemplate$(emailAndFonts: IEmailAndGoogleFonts, url = environment.convertorPath): Observable<IMjmlServerResponse> {
  //   return this.http.post<IMjmlServerResponse>(url, emailAndFonts);
  // }
}
