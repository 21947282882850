import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ILineHeight, TLineHeight } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-line-height',
  template: `
    <div class="group">
      <mat-form-field appearance="outline">
        <mat-label i18n="Line Height Settings|Change Line Height">{{ label }}</mat-label>
        <input
          matInput
          [(ngModel)]="lineHeight.value"
          type="number"
          step="1"
          (input)="markForCheck()"
          [disabled]="lineHeight.unit === 'none'"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Line Height Settings|Change Line Height Unit">Unit</mat-label>
        <mat-select
          placeholder="Unit"
          [(value)]="lineHeight.unit"
          (selectionChange)="markForCheck()"
          disableRipple
        >
          <mat-option *ngFor="let unit of units" [value]="unit" i18n>
            {{ getUnitLabel(unit) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineHeightComponent {
  @Input() lineHeight: ILineHeight;
  @Input() label = 'Line Height';
  @Input() units: TLineHeight[] = ['%', 'px', 'none'];

  constructor(private emailObject: IpEmailObjectStoreService) { }

  private unitsLabels: Map<string, string> = new Map([
    ['%', 'Percents'],
    ['px', 'Pixels'],
    ['none', 'None']
  ]);

  markForCheck() {
    this.emailObject.markForCheck();
  }

  getUnitLabel(unit: TLineHeight) {
    return this.unitsLabels.get(unit);
  }
}
