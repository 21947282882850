<div fxLayout [ngStyle]="getParentStyles()">
  <div [class]="getSocialListClasses()" [ngStyle]="getSocialListStyles()">
    <ng-container *ngIf="block.networks.length; else noSocialNetworks">
      <div class="social-list-item" *ngFor="let network of block.networks">
        <div [ngStyle]="getLabelStyles()">
          <img [src]="getSocialNetworkIcon(network.name)" [width]="block.options.iconSize.value"
            [height]="block.options.iconSize.value" [classList]="network.name" [alt]="network.name" />
        </div>
        <span *ngIf="network.label">{{ network.label }}</span>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noSocialNetworks>
  <p i18n="social block|Empty social list message">Please add some social networks.</p>
</ng-template>
