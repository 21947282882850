import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TDirection } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-direction',
  template: `
    <mat-form-field appearance="outline">
      <mat-label i18n="settings|Direction">Direction</mat-label>
      <mat-select
        i18n-placeholder="settings|Direction"
        placeholder="Direction"
        [(value)]="model.direction"
        (selectionChange)="markForCheck()"
        disableRipple
      >
        <mat-option *ngFor="let dir of getDirections()" [value]="dir">
          {{ getDirectionLabel(dir) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      host: {
        display: block;
        width: 100%;
      }
      mat-form-field {
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectionComponent {
  @Input() model: {
    direction: TDirection
  };

  private dirLabels: Map<string, string> = new Map([
    ['ltr', 'Left to Right'],
    ['rtl', 'Right to Left']
  ]);

  constructor(private emailObject: IpEmailObjectStoreService) { }

  markForCheck() {
    this.emailObject.markForCheck();
  }

  getDirections(): TDirection[] {
    return ['ltr', 'rtl'];
  }

  getDirectionLabel(dir: TDirection): string {
    return this.dirLabels.get(dir);
  }
}
