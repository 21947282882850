import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IPadding } from '../interfaces';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-padding',
  template: `
    <div class="group four">
      <mat-form-field appearance="outline">
        <mat-label i18n="Padding Settings|Padding Top">Top</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          (input)="markForCheck()"
          [(ngModel)]="padding.top"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Padding Settings|Padding Right">Right</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          (input)="markForCheck()"
          [(ngModel)]="padding.right"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Padding Settings|Padding Bottom">Bottom</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          (input)="markForCheck()"
          [(ngModel)]="padding.bottom"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Padding Settings|Padding Left">Left</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          (input)="markForCheck()"
          [(ngModel)]="padding.left"
        />
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaddingComponent {
  @Input() padding: IPadding;

  constructor(private emailObject: IpEmailObjectStoreService) { }

  markForCheck() {
    this.emailObject.markForCheck();
  }
}
