import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { IUserTemplate, IUserModule, IIPEmail, IMjmlServerResponse, IUserTemplateCategory, IUserImageCategory } from '../interfaces';
import { IPEmail } from '../classes/DefaultEmail';
import { deferOf } from '../utils';
import { environment } from 'src/environments/environment';

export interface IEmailAndGoogleFonts extends IPEmail { googleFonts: string[]; }

const NGB_HOST = environment.convertorPath;

@Injectable()
export abstract class IpUserRestApiService {
  getAllUserMergeFields$: Observable<string[]> = deferOf<string[]>([]);
  getAllUserModules$: Observable<IUserModule[]> = deferOf<IUserModule[]>([]);

  getAllUserTemplates$ = this.http.get<{ category: string, templates: string[] }[]>(NGB_HOST.concat('/templates')).pipe(
    map(templateList => templateList.map<IUserTemplateCategory>(({ category, templates }) => ({
      category, templates: templates.map(title => ({
        thumbPath: `https://ngb-templates.s3.amazonaws.com/${category}-${title}.jpg`,
        title,
        templateData: null
      }))
    }))),
    shareReplay()
  );

  constructor(public http: HttpClient) { }

  getUserTemplateData$(category: string, template: IUserTemplate) {
    if (template.templateData) {
      return deferOf(template);
    }
    return this.http.get<IIPEmail>(NGB_HOST.concat('/templates'), {
      params: { category, template: template.title }
    }).pipe(
      map(email => ({ ...template, templateData: email }) as IUserTemplate)
    );
  }

  userImageUpload$(body: FormData, uploadImagePath: string): Observable<HttpEvent<{ success: boolean; path: string; message?: string }>> {
    return this.http.request('POST', uploadImagePath, {
      body, reportProgress: true, responseType: 'json', observe: 'events',
    });
  }

  /**
   * Get all user's images to include in gallery
   */
  getUserImages$(): Observable<string[]> {
    return deferOf<string[]>([]);
  }

  createHTMLTemplate$(emailAndFonts: IEmailAndGoogleFonts, url = NGB_HOST): Observable<IMjmlServerResponse> {
    return this.http.post<IMjmlServerResponse>(url, emailAndFonts);
  }
}
