import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SendyService } from 'src/auth/api/sendy.service';
import { SaveAsModalResult } from './data/save-as-modal-result';
import { SaveAsModalParams } from './data/save-as-modal-params';

@Component({
  selector: 'app-save-as-modal',
  templateUrl: './save-as-modal.component.html',
  styleUrls: ['./save-as-modal.component.scss']
})

export class SaveAsModalComponent implements OnInit {
  formData: SaveAsModalResult;

  constructor(
    public dialogRef: MatDialogRef<SaveAsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveAsModalParams,
    private sendyService: SendyService
  ) {
   }

  ngOnInit(): void {

    const user = this.sendyService.getUser();
    if (this.data.itemType === 'campaign') {
      this.formData = {
        type: this.data.itemType,
        fromName: user.fromName,
        fromEmail: user.fromEmail,
        replyTo: user.replyTo,
        title: this.data.title,
      }
    }
    else {
      this.formData = {
        type: this.data.itemType,
        fromName: '',
        fromEmail: '',
        replyTo: '',
        title: this.data.title,
      }
    }
  }

  save() : void {
    this.dialogRef.close(this.formData);
  }
}