import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CdkDragDrop, CdkDrag } from '@angular/cdk/drag-drop';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Observable } from 'rxjs';

import { IStructure, IUserTemplate } from '../interfaces';
import { TBlocks } from '../classes/Elements';
import { IPEmail } from '../classes/DefaultEmail';
import { TPreviewDevice } from '../components/preview.component';
import { deferOf } from '../utils';

export type TExportType = 'html' | 'json' | 'mjml';
export type TImportType = 'json' | 'mjml';

export interface IAddStructureEvent extends CdkDragDrop<Set<IStructure>, Set<IStructure>> {
  item: CdkDrag<IStructure>;
}
export interface IAddBlockEvent extends CdkDragDrop<TBlocks[], TBlocks[]> {
  item: CdkDrag<TBlocks>;
}
export interface IPreviewToggleButton extends MatButtonToggleChange {
  value: TPreviewDevice;
}

@Injectable()
export abstract class IpUserMiddlewaresService {

  createHTMLTemplate(email: IPEmail): Observable<IPEmail> {
    return deferOf(email);
  }

  togglePreview(state: boolean): Observable<boolean> {
    return deferOf(state);
  }
  togglePreviewDevice(button: IPreviewToggleButton) {
    return deferOf(button.value);
  }

  chooseTemplate(category: string, template: IUserTemplate): Observable<{ category: string, template: IUserTemplate }> {
    return deferOf({ category, template });
  }
  uploadImage(path: string): Observable<string> {
    return deferOf(path);
  }

  addBlock(event: IAddBlockEvent, column: TBlocks[]) {
    return deferOf({ event, column });
  }
  editBlock(block: TBlocks): Observable<TBlocks> {
    return deferOf(block);
  }
  removeBlock(index: number, column: TBlocks[], block: TBlocks): Observable<{ index: number, column: TBlocks[] }> {
    return deferOf({ index, column });
  }
  duplicateBlock(index: number, column: TBlocks[], block: TBlocks): Observable<{ index: number, column: TBlocks[], block: TBlocks }> {
    return deferOf({ index, column, block });
  }
  disableBlockDragFromList(block: TBlocks): Observable<boolean> {
    return deferOf(false);
  }
  disableBlockDragWithinEmailBody(block: TBlocks): Observable<boolean> {
    return deferOf(false);
  }

  addStructure(event: IAddStructureEvent) {
    return deferOf(event);
  }
  editStructure(structure: IStructure): Observable<IStructure> {
    return deferOf(structure);
  }
  removeStructure(index: number, structure: IStructure): Observable<number> {
    return deferOf(index);
  }
  duplicateStructure(index: number, structure: IStructure): Observable<number> {
    return deferOf(index);
  }
  disableStructureDragFromList(structure: IStructure): Observable<boolean> {
    return deferOf(false);
  }
  disableStructureDragWithinEmailBody(structure: IStructure): Observable<boolean> {
    return deferOf(false);
  }

  preventWindowExit(event: BeforeUnloadEvent): Observable<BeforeUnloadEvent> {
    return deferOf(event);
  }

  exportFile(type: TExportType): Observable<TExportType> {
    return deferOf(type);
  }

  importFile(file: File): Observable<File> {
    return deferOf(file);
  }

  catchError(error: Error | HttpErrorResponse) {
    return deferOf(error);
  }
}
