import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';

import { IpUserRestApiService } from '../../user-rest-api-service/user-rest-api.service';
import { IP_CONFIG } from '../../tokens';
import { IForRootConf } from '../../interfaces';
import { IpUserMiddlewaresService } from '../../user-middleware-service/ip-middlewares.service';
import { IpUserInterfaceService } from '../../user-interface.service';

@Component({
  selector: 'ip-upload-image-gallery',
  templateUrl: './upload-image-gallery.component.html',
  styleUrls: ['./upload-image-gallery.component.scss']
})
export class UploadImageGalleryComponent implements OnDestroy {
  currentMenuItem: 'gallery' | 'upload' = 'gallery';
  choosedImage: string;
  uploadImagePreview: SafeResourceUrl;
  private imagePreviewObjectUrl: string;
  private uploadImageFile: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) public imageCategoryList: string[],
    @Inject(IP_CONFIG) private userConfig: IForRootConf,
    private matDialogRef: MatDialogRef<UploadImageGalleryComponent, string>,
    private sanitizer: DomSanitizer,
    private userRestApi: IpUserRestApiService,
    private middlewares: IpUserMiddlewaresService,
    private userInterface: IpUserInterfaceService
  ) { }

  changeCurrentMenuItem(item: 'gallery' | 'upload') {
    this.currentMenuItem = item;
  }

  addImageToTemplate(imagePath = this.choosedImage) {
    this.matDialogRef.close(imagePath);
  }

  previewImage({ target }: Event) {
    this.uploadImageFile = (target as HTMLInputElement).files.item(0);
    if (this.imagePreviewObjectUrl) {
      URL.revokeObjectURL(this.imagePreviewObjectUrl);
    }
    this.imagePreviewObjectUrl = URL.createObjectURL(this.uploadImageFile);
    this.uploadImagePreview = this.sanitizer.bypassSecurityTrustResourceUrl(this.imagePreviewObjectUrl);
  }

  startUploading() {
    const { csrf, uploadImagePath } = this.userConfig;
    const formData = new FormData();
    if (csrf) {
      formData.append(csrf.name, csrf.token);
    }
    formData.append('image', this.uploadImageFile);
    return this.userRestApi.userImageUpload$(formData, uploadImagePath).pipe(
      catchError((error: Error | HttpErrorResponse) => this.middlewares.catchError(error).pipe(
        switchMap(() => {
          this.userInterface.notify(error.message);
          return EMPTY;
        })
      )),
    ).subscribe((event: HttpEvent<{ success: boolean; path: string; message?: string }>) => {
      if (event.type === HttpEventType.UploadProgress) {
        // const percentDone = Math.round((100 * event.loaded) / event.total);
        // this.progress.next(percentDone);
      } else if (event instanceof HttpResponse) {
        if (!event.body.success) {
          this.userInterface.notify(event.body.message, 'Dismiss', null);
        } else {
          this.addImageToTemplate(event.body.path);
          this.userInterface.notify('Successfully uploaded.', null, 1000);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.imagePreviewObjectUrl) {
      URL.revokeObjectURL(this.imagePreviewObjectUrl);
    }
  }

}
