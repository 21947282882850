<div class="table-container">
    <mat-table matSort [dataSource]="dataSource">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header start="desc">#</mat-header-cell>
        <mat-cell *matCellDef="let template">{{template.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell class="description-cell" (click)="editCampaign(template.id)"
                  *matCellDef="let template">{{template.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="sent">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Sent</mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let template">
          <div *ngIf="template.sent !== ''">
            {{template.sent}}
          </div>
          <div *ngIf="template.sendDate !== '' && template.sent === ''">
            [Scheduled] {{template.sendDate.toLocaleString('en-US', {timeZone: 'America/New_York'})}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
        <mat-cell *matCellDef="let template" >
          <div *ngIf="template.sent == ''">
            <button mat-raised-button color="primary" (click)="editCampaign(template.id)">Edit</button>
          </div>
          <div *ngIf="template.sent !== ''">
            <button mat-raised-button color="primary" (click)="duplicateCampaign(template.id, template.name)">Duplicate</button>
          </div>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 20, 50]" showFirstLastButtons></mat-paginator>
