import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { switchMap } from 'rxjs/operators';

import { IpUserMiddlewaresService } from '../user-middleware-service/ip-middlewares.service';
import { UploadBottomSheetDialogComponent } from './upload-bottom-sheet-dialog/upload-bottom-sheet-dialog.component';
import { IpUserImageUploaderService } from './user-image-uploader.service';

export default class ImageUploader extends IpUserImageUploaderService {
  constructor(
    private bottonSheet: MatBottomSheet,
    private ipMiddlewresService: IpUserMiddlewaresService
  ) {
    super()
  }

  browse$() {
    return this.bottonSheet
      .open(UploadBottomSheetDialogComponent, {
        ariaLabel: 'Browse media'
      })
      .afterDismissed().pipe(switchMap(path => this.ipMiddlewresService.uploadImage(path)))
  }
}
