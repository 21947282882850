<div class="dialog-container">
  <h1 mat-dialog-title>You Must Supply An Unsubscribe Link.</h1>
  <div mat-dialog-content>
    <div fxLayout="column">
      <p *ngIf="data.saveType === 'existing'">It is illegal to send emails without an unsubscribe option. You can either revert your changes or create a simple unsubscribe action using the options below.</p>
      <p *ngIf="data.saveType === 'new'">It is illegal to send emails without an unsubscribe option. You can add the footer module or create a simple unsubscribe action from the options below.</p>
    </div>
  </div>
  <hr />
  <div mat-dialog-actions>
    <button *ngIf="data.saveType === 'new'" mat-button (click)="footer()">Add Footer</button>
    <button *ngIf="data.saveType === 'existing'" mat-button (click)="revert()">Revert</button>
    <button mat-button (click)="custom()">Custom Footer</button>
  </div>
</div>
