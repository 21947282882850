import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {SendyService} from '../../auth/api/sendy.service';
import {ActivatedRoute, Router} from '@angular/router';
import { TitleService } from '../../services/title.service';
import { TemplateData } from 'src/auth/api/template-data.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SaveAsModalComponent } from 'src/modals/save-as-modal/save-as-modal.component';
import { SaveAsModalParams } from 'src/modals/save-as-modal/data/save-as-modal-params';
import { takeUntil } from 'rxjs/operators';
import { SaveAsModalResult } from 'src/modals/save-as-modal/data/save-as-modal-result';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject<any>();
  
  dataSource: MatTableDataSource<TemplateData>;
  displayedColumns = ['id', 'name', 'sent', 'edit'];
  public now = new Date();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private isInitializedSource = new BehaviorSubject<boolean>(false);
  public isInitialized$ = this.isInitializedSource.asObservable();


  constructor(
    private sendyService: SendyService,
    private route: ActivatedRoute,
    private titleService: TitleService,
    public dialog: MatDialog,
    private router: Router) {
  }


  ngOnInit(): void {
    this.sendyService.getCampaigns()
      .then(res => {
        this.dataSource = new MatTableDataSource<TemplateData>(res.sort((a, b) => parseInt(b.id) - parseInt(a.id)));

        this.isInitializedSource.next(true);
    });
  }

  ngAfterViewInit(): void {
    this.isInitialized$
        .subscribe((isInitialized) => {
          if (isInitialized) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
  }

  editCampaign(id: string){
    this.router.navigateByUrl('/builder/campaign/' + id);
  }

  async duplicateCampaign(id: string, oldTitle: string) {
    const saveAsModal = this.dialog.open<SaveAsModalComponent, SaveAsModalParams>(SaveAsModalComponent, {
      autoFocus: true,
      width: '450px',
      data: {
        title: oldTitle,
        itemType: 'campaign',
      }
    });

    const saveAsResult = await saveAsModal.afterClosed()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .toPromise<SaveAsModalResult>();

    
    if (saveAsResult) {
    
    const fromEmail = (saveAsResult != null) ? saveAsResult.fromEmail : null;
    const fromName = (saveAsResult != null) ? saveAsResult.fromName : null;
    const replyTo = (saveAsResult != null) ? saveAsResult.replyTo : null;
    const title = (saveAsResult != null) ? saveAsResult.title : null;

    this.sendyService.duplicateCampaign(id , fromEmail, fromName, replyTo, title)
      .then(sent => {
        this.router.navigateByUrl(`/builder/campaign/${sent.campaign_id}`);
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

}
