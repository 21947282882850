import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { IFont, IFontFamily } from '../interfaces';
import { IP_GOOGLE_FONTS, IP_FALLBACK_FONTS } from '../tokens';
import { IpEmailObjectStoreService } from '../ip-email-object-store.service';

@Component({
  selector: 'ip-font-styles',
  template: `
    <div class="group two">
      <mat-form-field appearance="outline" *ngIf="hasProperty('family')">
        <mat-label i18n="settings|Font Family">Family</mat-label>
        <mat-select [(value)]="font.family" disableRipple (selectionChange)="markForCheck()">
          <mat-option
            *ngFor="let name of fontFamilyList"
            [ngStyle]="{ fontFamily: name }"
            [value]="name"
          >
            {{ name }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="isGoogleFont()" i18n="settings|Partial support">Partial support.</mat-hint>
        <mat-hint *ngIf="!isGoogleFont()" i18n="settings|Full support">Full support.</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="hasProperty('fallback')">
        <mat-label i18n="settings|Font Fallback">Fallback</mat-label>
        <mat-select
          [(value)]="font.fallback"
          [disabled]="!isGoogleFont()"
          disableRipple
          (selectionChange)="markForCheck()"
        >
          <mat-option
            *ngFor="let name of fallbackFonts"
            [ngStyle]="{ fontFamily: name }"
            [value]="name"
          >
            {{ name }}
          </mat-option>
        </mat-select>
        <mat-hint i18n="settings|Full support">Full support.</mat-hint>
      </mat-form-field>
    </div>

    <div class="group three">
      <mat-form-field appearance="outline" *ngIf="hasProperty('size')">
        <mat-label i18n="settings|Font Size">Size</mat-label>
        <input
          matInput
          type="number"
          max="30"
          min="10"
          step="1"
          (input)="markForCheck()"
          [(ngModel)]="font.size"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="hasProperty('weight')">
        <mat-label i18n="settings|Font Weigth">Weight</mat-label>
        <mat-select placeholder="Weight" [(value)]="font.weight" disableRipple (selectionChange)="markForCheck()">
          <mat-option *ngFor="let weight of fontWeightList" [value]="weight">
            {{ weight }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="min-width: 90px;">
        <mat-label i18n="settings|Font Style">Style</mat-label>
        <mat-select placeholder="Style" [(value)]="font.style" disableRipple (selectionChange)="markForCheck()">
          <mat-option *ngFor="let style of ['italic', 'normal']" [value]="style" i18n>
            {{ style }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontStylesComponent {
  @Input() font: IFont;
  private fontsMap = new Map<string, number[]>();

  fontFamilies: IFontFamily;
  constructor(
    private ipEmailObject: IpEmailObjectStoreService,
    @Inject(IP_GOOGLE_FONTS) googleFonts: string[],
    @Inject(IP_FALLBACK_FONTS) public fallbackFonts: string[],
  ) {
    googleFonts.forEach(font => {
      const [family, ...weights] = font.match(/[^\d:,]{2,}|\d{3}/g);
      this.fontsMap.set(family.replace('+', ' '), [...new Set(weights.map(Number))]);
    });
  }

  markForCheck() {
    this.ipEmailObject.markForCheck();
  }

  isGoogleFont() {
    return this.fontsMap.has(this.font.family);
  }

  get fontWeightList() {
    return this.isGoogleFont() ? this.fontsMap.get(this.font.family) : [100, 400, 500, 700, 900];
  }

  get fontFamilyList() {
    return [...this.fontsMap.keys(), ...this.fallbackFonts];
  }

  hasProperty(property: string) {
    return this.font.hasOwnProperty(property);
  }
}
