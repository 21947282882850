import {
  Directive,
  ComponentFactoryResolver,
  ViewContainerRef,
  Input,
  Type
} from '@angular/core';
import { TextElementComponent } from '../elements/text-element/text-element.component';
import { ImageComponent } from '../elements/image/image.component';
import { ButtonComponent } from '../elements/button/button.component';
import { DividerComponent } from '../elements/divider/divider.component';
import { SpacerComponent } from '../elements/spacer/spacer.component';
import { SocialComponent } from '../elements/social/social.component';
import { TBlocks } from '../classes/Elements';

const cStore: Map<string, Type<any>> = new Map();
// https://blog.angularindepth.com/here-is-what-you-need-to-know-about-dynamic-components-in-angular-ac1e96167f9e
@Directive({
  selector: '[ipDynamicComponent]'
})
export class DynamicComponentDirective {
  @Input() set ipDynamicComponent(block: TBlocks) {
    const component = this.cResolver.resolveComponentFactory(
      cStore.get(block.type)
    );
    this.viewContainerRef.createComponent(component).instance.block = block;
  }
  constructor(
    private cResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {
    cStore.set('text', TextElementComponent);
    cStore.set('image', ImageComponent);
    cStore.set('button', ButtonComponent);
    cStore.set('divider', DividerComponent);
    cStore.set('spacer', SpacerComponent);
    cStore.set('social', SocialComponent);
  }
}
