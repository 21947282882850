import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, EMPTY } from 'rxjs';
import {
  HttpEventType,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { catchError, finalize, switchMap, } from 'rxjs/operators';

import { bytesToSize } from '../../utils';
import { IpEmailBuilderService } from '../../ip-email-builder.service';
import { IpUserMiddlewaresService } from '../../user-middleware-service/ip-middlewares.service';
import { IpUserInterfaceService } from '../../user-interface.service';
import { IpUserRestApiService } from '../../user-rest-api-service/user-rest-api.service';

@Component({
  selector: 'ip-upload-bottom-sheet-dialog',
  templateUrl: './upload-bottom-sheet-dialog.component.html',
  styleUrls: ['./upload-bottom-sheet-dialog.component.css']
})
export class UploadBottomSheetDialogComponent implements OnDestroy {
  @ViewChild('uploadInput', { static: true }) uploadInput: ElementRef<HTMLInputElement>;
  choosedImage: File;
  uploading = false;
  progress = new Subject<number>();
  private objectUrl: string;

  constructor(
    private sanitizer: DomSanitizer,
    private ngb: IpEmailBuilderService,
    private userRestApi: IpUserRestApiService,
    private userInterface: IpUserInterfaceService,
    private middlewares: IpUserMiddlewaresService,
    private bottomSheetRef: MatBottomSheetRef<UploadBottomSheetDialogComponent>
  ) { }

  get imageInfo() {
    if (this.choosedImage) {
      const { lastModified, size } = this.choosedImage;
      const modTime = new Date(lastModified);
      return `${modTime.toLocaleString()}, ${bytesToSize(size)}.`;
    }
    return '';
  }

  previewLink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.objectUrl);
  }

  openBrowserModal(event: MouseEvent) {
    event.preventDefault();
    const { uploadImagePath } = this.ngb.config;
    if (!this.uploading && uploadImagePath) {
      this.uploadInput.nativeElement.click();
    } else if (!uploadImagePath) {
      this.userInterface.notify(`Hm ... this isn't a bug, it seems 'uploadImagePath' is empty!`);
    }
  }

  uploadInputChanged() {
    const { nativeElement } = this.uploadInput;
    if (nativeElement.files.length) {
      this._startUploading(nativeElement.files.item(0));
    }
  }

  private _startUploading(image: File) {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
    this.objectUrl = URL.createObjectURL(image);
    this.uploading = true;

    const { uploadImagePath, csrf } = this.ngb.config;

    const formData = new FormData();
    if (csrf) {
      formData.append(csrf.name, csrf.token);
    }
    formData.append('image', image);

    return this.userRestApi.userImageUpload$(formData, uploadImagePath).pipe(
      catchError((error: Error | HttpErrorResponse) => this.middlewares.catchError(error).pipe(
        switchMap(() => {
          this.userInterface.notify(error.message);
          return EMPTY;
        })
      )),
      finalize(() => this.progress.complete())
    ).subscribe((event: HttpEvent<{ success: boolean; path: string; message?: string }>) => {
      if (event.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round((100 * event.loaded) / event.total);
        this.progress.next(percentDone);
      } else if (event instanceof HttpResponse) {
        if (!event.body.success) {
          this.userInterface.notify(event.body.message, 'Dismiss', null);
        } else {
          this.bottomSheetRef.dismiss(event.body.path);
          this.userInterface.notify('Successfully uploaded.', null, 1000);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
  }
}
