import { InjectionToken, inject } from '@angular/core';

import { IP_CONFIG, IP_DEFAULT_CONFIG } from './tokens';

export const LIBRARY_IS_PRO = new InjectionToken<boolean>('Library environment', {
    providedIn: 'any',
    factory: () => {
        const { xApiKey } = inject(IP_CONFIG);
        return !(!xApiKey || xApiKey.length < 40 || xApiKey === IP_DEFAULT_CONFIG.xApiKey);
    }
})