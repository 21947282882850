import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ButtonBlock } from '../../classes/Elements';
import {
  createFont,
  createPadding,
  createBorder,
  createLineHeight
} from '../../utils';

@Component({
  selector: 'ip-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ButtonComponent {
  @Input() block: ButtonBlock;

  getButtonStyles() {
    const {
      backgroundColor,
      border,
      color,
      font,
      lineHeight,
      innerPadding,
      fullWidth
    } = this.block.options;

    return {
      color,
      width: fullWidth ? '100%' : 'auto',
      backgroundColor,
      ...createFont(font),
      ...createPadding(innerPadding),
      ...createBorder(border),
      ...createLineHeight(lineHeight)
    };
  }

  getParentStyles() {
    const { align, padding } = this.block.options;

    return {
      justifyContent:
        (align === 'center' && 'center') ||
        (align === 'right' && 'flex-end') ||
        'flex-start',
      ...createPadding(padding)
    };
  }
}
