import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

import { IpUserMiddlewaresService } from '../user-middleware-service/ip-middlewares.service';

export type TPreviewDevice = 'desktop' | 'smartphone' | 'tablet';

const devicesMap = new Map([
  ['desktop', '1 1 100%'],
  ['smartphone', '1 1 360px'],
  ['tablet', '1 1 768px']
]);

@Component({
  selector: 'ip-preview-template',
  template: `
    <iframe [src]="templateSrc" sandbox="allow-scripts" [fxFlex]="getFlexWidth()"></iframe>
    <mat-button-toggle-group value="desktop" (change)="changeDevice($event)" vertical>
      <mat-button-toggle value="desktop" matTooltip="Desktop / 100%" matTooltipPosition="left">
        <mat-icon>desktop_windows</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="tablet" matTooltip="Tablet / 768px" matTooltipPosition="left">
        <mat-icon>tablet</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="smartphone" matTooltip="Smartphone / 360px" matTooltipPosition="left">
        <mat-icon>smartphone</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  `,
  styles: [
    `
      iframe {
        border: 0;
        will-change: width;
        transition: all 500ms ease-in-out
      }

      /* iframe a[href] {
        pointer-events: none;
      } */

      /* iframe.active {
        opacity: 1;
      } */

      mat-button-toggle-group {
        position: absolute;
        right: 0;
        background: white;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewTemplateComponent implements OnInit, OnDestroy {
  @Input() template: string;
  private src: string;
  private device: TPreviewDevice = 'desktop';
  templateSrc: SafeResourceUrl;

  constructor(
    private middlewares: IpUserMiddlewaresService,
    private sanitizer: DomSanitizer
  ) { }

  changeDevice(button: MatButtonToggleChange) {
    return this.middlewares.togglePreviewDevice(button).pipe(take(1)).subscribe(device => {
      this.device = device;
    });
  }

  getFlexWidth() {
    return devicesMap.get(this.device)
  }

  ngOnInit() {
    this.src = URL.createObjectURL(new Blob([this.template], { type: 'text/html' }));
    this.templateSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
  }

  ngOnDestroy() {
    if (this.src) {
      URL.revokeObjectURL(this.src);
    }
  }
}
